import React, { useEffect, useState,useContext } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper, Input } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import MetaTags from "react-meta-tags";
import SearchIcon from "@material-ui/icons/Search";
import GetNews from "../../components/card/GetNews";
import Pagination from "@material-ui/lab/Pagination";
import { getNewsPagination, getAllNews } from "../../action/news";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import "./hideScrollbar.css";


const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "30vh",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
  paperSearch: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
    maxWidth: "500px",
  },
  paperSearchMobile: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
    width: "280px",
  },
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      borderBottom: "2px solid #de148c",
      // textDecorationColor: "orange",
      // backgroundColor: "orange",
      // color: "orange"
    },
    // "&$selected:hover": {
    //   backgroundColor: "purple",
    //   color: "white"
    // },
    // "&:hover": {
    //   backgroundColor: "orange",
    //   color: "orange"
    // }
  },
  selected: {},
})(MuiListItem);

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};
function News() {
  const classes = useStyles();
  const theme = useTheme();
  const [newsData, setNewsData] = useState({});
  const [initialNews, setInitialNews] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [idCategory, setIdCategory] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  // const [selected, setSelected] = useState([]);
  const [position, setPosition] = useState(0);

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  
    return (
      <div style={{display:"flex", marginTop:"140px", marginRight:"10px", justifyContent:"center", alignItems:"center", height:"60px", width:"60px", backgroundColor:"#DE148C", color:"white",borderRadius:"50%"}} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <Typography style={{fontSize:"3vh", fontWeight:"bold"}}>
          {"<"}
        </Typography>
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  
    return (
      <div style={{display:"flex", marginTop:"140px", justifyContent:"center", marginLeft:"10px", alignItems:"center", height:"60px", width:"60px", backgroundColor:"#DE148C", color:"white",borderRadius:"50%"}} disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <Typography style={{fontSize:"3vh", fontWeight:"bold"}}>
          {">"}
        </Typography>
      </div>
    );
  }

  useEffect(() => {
    // getNewsPagination(page, idCategory).then((result) => {
    //   // console.log(result)

    //   setNewsData(result.news);
    //   // setNews(result.news)
    //   // console.log(result.count)
    //   var lenght = result.count / 18;
    //   setTotalPage(Math.ceil(lenght));
    // });

    getAllNews().then((result) => {
      setInitialNews(result.news);
      setNewsData(result.news);
    });

    setIsLoading(false);
    // const fetch = async () => {
    //   const result = await axios.get(`https://api.ur-hub.com/qr/v2/news/getAll.php`)
    //   setNews(result.data.news)
    // }
    // fetch()
  }, [page, idCategory]);

  const handleChangePage = (event, value) => {
    // console.log(value)
    setPage(value);
  };

  const handleCategory = (e) => {
    if (e === null) {
      setIdCategory("");
    } else {
      // console.log(e)
      setIdCategory(e);
      // setTrigerIdSubCategory(!trigerIdSubCategory)
    }
  };

  const handleSearch = (event) => {
    if (event.target.value !== "") {
      setNewsData(
        initialNews.filter(
          (data) =>
            data["title"]
              .toLowerCase()
              .indexOf(event.target.value.toLowerCase()) > -1
        )
      );
    } else {
      setNewsData(initialNews);
    }
  };
  return (
    <div style={{ marginBottom: "5%" }}>
      <MetaTags>
        <title>Blog | UR - Self Order & POS App</title>
        <meta name="title" content="Blog" />
        <meta name="description" content="Info Blog dari Aplikasi UR." />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Info Blog dari Aplikasi UR." />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Blog" />
        <meta
          property="twitter:description"
          content="Info Blog dari Aplikasi UR."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>
      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Blog
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="https://ik.imagekit.io/urhub/assets/misc/promo.png"
                  alt="promo"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box pt={10}>
        <Container>
          {/* <Grid item xs={12}>
              <Typography variant={isDesktop ? 'h2' : 'h6'} style={{ fontWeight: 'bold' }} color="primary" align="center"> Blog Seputar UR </Typography>
              <hr />
            </Grid> */}
          <Grid item xs={12} align="center">
            <Paper
              className={
                isDesktop ? classes.paperSearch : classes.paperSearchMobile
              }
              // variant="outlined"
              elevation={5}
            >
              <SearchIcon className={classes.icon} />
              <Input
                placeholder="Cari info tentang UR"
                className={classes.input}
                disableUnderline
                onChange={handleSearch}
              />
            </Paper>
          </Grid>
        </Container>
      </Box>
      <Box pt={5}>
        <Container>
          <Grid item xs={12} align="center">
            <Tabs
              // value={value}
              // onChange={handleChange}
              centered
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable auto tabs example"
            >
              <List style={flexContainer}>
                <a>
                  <ListItem
                    button
                    key="Semua"
                    onClick={() => handleCategory(0)}
                    selected={idCategory === 0 ? true : false}
                  >
                    <ListItemText
                      style={{ color: "#2e2e2e" }}
                      primary="Semua"
                    />
                  </ListItem>
                </a>

                <ListItem
                  button
                  key="Agent"
                  onClick={() => handleCategory(1)}
                  selected={idCategory === 1 ? true : false}
                >
                  <a>
                    <ListItemText
                      style={{ color: "#2e2e2e" }}
                      primary="Agent"
                    />
                  </a>
                </ListItem>

                <ListItem
                  button
                  key="Partner"
                  onClick={() => handleCategory(2)}
                  selected={idCategory === 2 ? true : false}
                >
                  <a>
                    <ListItemText
                      style={{ color: "#2e2e2e" }}
                      primary="Partner"
                    />
                  </a>
                </ListItem>

                <ListItem
                  button
                  key="General"
                  onClick={() => handleCategory(3)}
                  selected={idCategory === 3 ? true : false}
                >
                  <a>
                    <ListItemText
                      style={{ color: "#2e2e2e" }}
                      primary="General"
                    />
                  </a>
                </ListItem>
              </List>
            </Tabs>
          </Grid>

          <Grid container xs={12} justify="center">
            {isLoading ? (
              <Grid item xs={12} md={3}>
                <CircularProgress />
              </Grid>
            ) : newsData ? (
              <div style={{maxWidth:"100%", height:"auto"}}>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                  {
                    Object.entries(newsData).map((data, id) => {
                      return (
                        <GetNews 
                        classes={classes} 
                        data={data} 
                        itemId={id} // NOTE: itemId is required for track items
                        title={id}
                        key={id}
                      />);
                    })
                  }
                </ScrollMenu>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Container>
      </Box>
      <Box pt={10}>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Pagination
              count={totalPage}
              page={page}
              onChange={handleChangePage}
            />
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default News;
