import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";

import Pagination from "@material-ui/lab/Pagination";
import Tabs from "@material-ui/core/Tabs";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCareerCategory, getCareerPagination } from "../../action/career";
import GetCareer from "../../components/card/GetCareer";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
  tableMargin: {
    paddingTop: "10px",
    paddingRight: "120px",
    paddingBottom: "10px",
    paddingLeft: "120px",
    textAlign: "left",
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      borderBottom: "2px solid #de148c",
      // textDecorationColor: "orange",
      // backgroundColor: "orange",
      // color: "orange"
    },
    // "&$selected:hover": {
    //   backgroundColor: "purple",
    //   color: "white"
    // },
    // "&:hover": {
    //   backgroundColor: "orange",
    //   color: "orange"
    // }
  },
  selected: {},
})(MuiListItem);

function Karir() {
  const classes = useStyles();
  const [career, setCareer] = useState({});
  const [buttonCategory, setButtonCategory] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [idCategory, setIdCategory] = useState("0");
  const [isScroll, setIsScroll] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCareerCategory().then((result) => {
      if (result.success === 1) {
        // console.log(result.categories)
        let listCategory = [];

        listCategory[0] = {
          value: "0",
          label: "Semua",
        };
        // console.log(listCategory)
        let i = 1;
        result.categories.map((value) => {
          listCategory[i] = {
            value: value.id,
            label: value.name,
          };
          i++;
          return i;
        });
        // console.log(listCategory.length)
        if (listCategory.length >= 12) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }

        setButtonCategory(listCategory);
      } else {
        const timer = setTimeout(() => {
          // setIsLoading(false)
          // setNoData(true)
        }, 2000);
        return () => clearTimeout(timer);
      }
    });

    getCareerPagination(page, idCategory).then((result) => {
      // console.log(result.careers)
      setCareer(result.careers);
      var lenght = result.count / 10;
      setTotalPage(Math.ceil(lenght));
    });

    setIsLoading(false);
  }, [page, idCategory]);

  const handleChangePage = (event, value) => {
    // console.log(value)
    setPage(value);
  };

  const handleCategory = (e) => {
    if (e === null) {
      setIdCategory("");
    } else {
      // console.log(e)
      setIdCategory(e);
      // setTrigerIdSubCategory(!trigerIdSubCategory)
    }
  };
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  };
  return (
    <>
      <MetaTags>
        <title>Karir | UR - Self Order & POS App</title>
        <meta name="title" content="Karir" />
        <meta
          name="description"
          content="Ayo kita bangun bersama aplikasi UR."
        />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Karir" />
        <meta
          property="og:description"
          content="Ayo kita bangun bersama aplikasi UR."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Karir" />
        <meta
          property="twitter:description"
          content="Ayo kita bangun bersama aplikasi UR."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Karir
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="https://ik.imagekit.io/urhub/assets/misc/join-us.png"
                  alt="promo"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box display="flex" justifyContent="center" pt={5}>
        <Container>
          {/* <Paper className={classes.tabs}> */}
          <Tabs
            // value={value}
            // onChange={handleChangePage}
            centered={isScroll ? true : false}
            indicatorColor="primary"
            textColor="primary"
            variant={isScroll ? "fullWidth" : "scrollable"}
            scrollButtons={isScroll ? "off" : "on"}
            aria-label="scrollable auto tabs example"
          >
            <List style={flexContainer}>
              {isLoading ? (
                <Grid item xs={12} md={3}>
                  <CircularProgress />
                </Grid>
              ) : buttonCategory ? (
                buttonCategory.map((data) => {
                  // console.log(idSubCategory)
                  // console.log(data.value)
                  return (
                    <ListItem
                      button
                      key={data.label}
                      onClick={() => handleCategory(data.value)}
                      selected={idCategory === data.value ? true : false}
                    >
                      <ListItemText
                        style={{ color: "#2e2e2e" }}
                        primary={data.label}
                      />
                    </ListItem>
                  );
                })
              ) : (
                ""
              )}
            </List>
          </Tabs>
          {/* </Paper> */}
          {isLoading ? (
            <Grid item xs={12} md={3}>
              <CircularProgress />
            </Grid>
          ) : career ? (
            Object.entries(career).map((data) => {
              return <GetCareer classes={classes} data={data} />;
            })
          ) : (
            ""
          )}
        </Container>
      </Box>

      <Box py={10}>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Pagination
              count={totalPage}
              page={page}
              onChange={handleChangePage}
            />
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Karir;
