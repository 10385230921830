import Axios from 'axios';
const API_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL

export const getAllNews = async () => {

  const result = await Axios.get(
    API_KEY + 'v2/news/getAll.php');

  return result.data;
};

export const getNewsPagination = async (page, idCategory) => {
  console.log(page)
  console.log(idCategory)

  const result = await Axios.get(
    API_KEY + 'v2/news/getNewsPagination.php?load=18&page=' + page + `&category=` + idCategory);
  console.log(API_KEY + 'v2/news/getNewsPagination.php?load=9&page=' + page + `&category=` + idCategory)
  // console.log(result.data)
  return result.data;
};

export const getNewsBySlug = async (slug) => {

  const result = await Axios.get(
    API_KEY + 'v2/news/getBySlug.php?slug=' + slug);

  return result.data;
};