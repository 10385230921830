import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
}));

function Faq() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // User FAQ
  const [openCaraDaftarAkun, setOpenCaraDaftarAkun] = React.useState(false);
  const [openCaraMemesan, setOpenCaraMemesan] = React.useState(false);
  const [openKoneksi, setOpenKoneksi] = React.useState(false);
  const [openKeamanan, setOpenKeamanan] = React.useState(false);
  const [openKendala, setOpenKendala] = React.useState(false);
  const [openLayanan, setOpenLayanan] = React.useState(false);
  const [openPembayaran, setOpenPembayaran] = React.useState(false);
  const [openMetodePembayaran, setOpenMetodePembayaran] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openTempatMemesan, setOpenTempatMemesan] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);

  // Partner FAQ
  const [openApaUR, setOpenApaUR] = React.useState(false);
  const [openDaftarPartner, setOpenDaftarPartner] = React.useState(false);
  const [openDemoPartner, setOpenDemoPartner] = React.useState(false);
  const [openDataPartner, setOpenDataPartner] = React.useState(false);
  const [openPaketPartner, setOpenPaketPartner] = React.useState(false);
  const [openTrialPartner, setOpenTrialPartner] = React.useState(false);
  const [openPembayaranPartner, setOpenPembayaranPartner] =
    React.useState(false);
  const [openCabangPartner, setOpenCabangPartner] = React.useState(false);
  const [openHardwarePartner, setOpenHardwarePartner] = React.useState(false);
  const [openInstalasiPartner, setOpenInstalasiPartner] = React.useState(false);
  const [openWalletPartner, setOpenWalletPartner] = React.useState(false);
  const [openPajakPartner, setOpenPajakPartner] = React.useState(false);
  const [openMenuPartner, setOpenMenuPartner] = React.useState(false);
  const [openMemantauPartner, setOpenMemantauPartner] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <MetaTags>
        <title>FAQs | UR - Self Order & POS App</title>
        <meta name="title" content="FAQs" />
        <meta
          name="description"
          content="Pertanyaan terbanyak tentang aplikasi UR."
        />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="FAQs" />
        <meta
          property="og:description"
          content="Pertanyaan terbanyak tentang aplikasi UR."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="FAQs" />
        <meta
          property="twitter:description"
          content="Pertanyaan terbanyak tentang aplikasi UR."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/faq.png"
                  alt="icon faq ur"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box pt={15}>
          <Grid container justify="center" className={classes.box}>
            <Grid item xs={12}>
              <div>
                <AppBar color="transparent" position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    centered
                    aria-label="simple tabs example"
                  >
                    <Tab label="FAQ User" {...a11yProps(0)} />
                    <Tab label="FAQ Partner" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>

                <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
                  <TabPanel value={value} index={0}>
                    <Typography
                      align="center"
                      variant="h2"
                      gutterBottom
                      color="primary"
                      style={{ paddingTop: "20px" }}
                    >
                      FAQ User
                    </Typography>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenCaraDaftarAkun(!openCaraDaftarAkun)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bagaimana cara daftar akun?" />
                        {openCaraDaftarAkun ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openCaraDaftarAkun}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              <ol>
                                <li>Download Aplikasi UR disini.</li>
                                <li>Buka aplikasi UR.</li>
                                <li>
                                  Masukkan nomor HP yang kamu gunakan (pastikan
                                  nomor telepon sama dengan yang kamu gunakan
                                  untuk e-wallet).
                                </li>
                                <li>Isi data diri kamu.</li>
                                <li>
                                  Selamat kamu terdaftar sebagai pengguna UR!
                                </li>
                              </ol>
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenCaraMemesan(!openCaraMemesan)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bagaimana cara melakukan memesan lewat aplikasi UR pada saat saya sudah di outlet?" />
                        {openCaraMemesan ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openCaraMemesan}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kamu dapat menggunakan aplikasi UR untuk memesan
                              makanan dan minuman. Temukan berbagai macam
                              pilihan makanan dari banyak outlet yang berbeda.
                              Ketika kamu menggunakan aplikasi UR, kamu dapat
                              melakukan lebih dari satu pemesanan dalam waktu
                              yang sama tanpa batas minimum pembelian. Berikut
                              cara melakukan pesanan dengan aplikasi UR:
                              <ol>
                                <li>
                                  Pada beranda aplikasi UR, pilih pesan makanan.
                                </li>
                                <li>
                                  Scan kode QR yang tertera pada table
                                  restaurant.{" "}
                                </li>
                                <li>Pilih menu yang kamu pesan.</li>
                                <li>Pastikan pesanan kamu sudah benar.</li>
                                <li>
                                  Setelah memastikan pesanan kamu benar, pilih
                                  tombol Pay / Bayar untuk melanjutkan proses
                                  berikutnya.
                                </li>
                                <li>
                                  Setelah pesanan dikonfirmasi, maka kamu dapat
                                  memilih metode pembayaran sesusai dengan
                                  keinginan kamu.
                                </li>
                              </ol>
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenKoneksi(!openKoneksi)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah penggunaan aplikasi UR memerlukan koneksi internet?" />
                        {openKoneksi ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openKoneksi} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Jelas perlu , aplikasi UR memerlukan koneksi
                              internet untuk dijalankan agar setiap transaksi
                              yang dilakukan oleh kamu berjalan dengan baik.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenKeamanan(!openKeamanan)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bagaimana dengan keamanan data saya?" />
                        {openKeamanan ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openKeamanan} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              UR patuh kepada peraturan dan perundangan yang
                              berlaku, kami menjamin keamanan seluruh data
                              pengguna dan tidak membagi ke pihak lain tanpa
                              persetujuan pengguna sesuai dengan kebijakan
                              keamanan dan privasi yang telah kami cantumkan.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenKendala(!openKendala)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Jika saya mengalami kendala atau problem terkait penggunaan UR, apa yang harus saya lakukan ?" />
                        {openKendala ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openKendala} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kamu dapat menghubungi Contact Center kami. Tim
                              Customer Experience dan Technical Consultant kami
                              akan membantu menyelesaikan problem yang kamu
                              alami.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenLayanan(!openLayanan)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah layanan Customer Service UR bersifat 24 jam?" />
                        {openLayanan ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openLayanan} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tim Customer Service UR siap membantu kendala dan
                              pertanyaan anda selama 24 jam.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenMetodePembayaran(!openMetodePembayaran)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apa saja metode pembayaran yang bisa dipakai?" />
                        {openMetodePembayaran ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openMetodePembayaran}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              E-wallet, tunai, debit, kredit, tetapi jika kamu
                              bayar dengan tunai, debit, kredit maka kamu harus
                              datang ke kasir untuk melakukan pembayaran dan
                              validasi pembayaran agar pesanan kamu bisa di
                              proses.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenPembayaran(!openPembayaran)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah sekarang UR sudah terkoneksi dengan pembayaran e-Wallet ?" />
                        {openPembayaran ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openPembayaran}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Sampai saat ini aplikasi UR sudah terkoneksi
                              dengan e-wallet dari LinkAja, Dana, Gopay dan OVO.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenHistory(!openHistory)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah saya bisa lihat riwayat dan status pemesanan saya?" />
                        {openHistory ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openHistory} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tentu saja bisa, aplikasi UR juga menyediakan
                              fitur riwayat pesanan jadi kamu bisa lihat status
                              pemesanan kamu.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenTempatMemesan(!openTempatMemesan)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dimana saja saya bisa pesan makan dengan aplikasi UR ?" />
                        {openTempatMemesan ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openTempatMemesan}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kita sudah menyediakan fitur lokasi di aplikasinya
                              jadi kamu bisa tau outlet mana aja yang sudah
                              bekerja sama dengan UR.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenEditProfile(!openEditProfile)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah saya bisa edit data diri?" />
                        {openEditProfile ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openEditProfile}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tentu bisa, kamu bisa mengubah nama, alamat, jenis
                              kelamin, dan tanggal lahir.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Typography
                      align="center"
                      variant="h2"
                      gutterBottom
                      color="primary"
                      style={{ paddingTop: "20px" }}
                    >
                      FAQ Partner
                    </Typography>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem button onClick={() => setOpenApaUR(!openApaUR)}>
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apa itu UR?" />
                        {openApaUR ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openApaUR} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              UR itu adalah layanan yang memudahkan kamu untuk
                              melakukan transaksi di restoran favoritmu.
                              Mengatur keuangan lebih mudah, transaksi lebih
                              cepat dan membuat pelanggan pun senang. Pelanggan
                              tidak perlu lagi menunggu untuk pesan makanan.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenDaftarPartner(!openDaftarPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apa yang saya dapatkan dengan bergabung bersama UR?" />
                        {openDaftarPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openDaftarPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Banyak banget! Dengan bergabung Bersama UR
                              Partner, kamu dapat mengolah usaha mu dengan lebih
                              baik. Kamu dapat mengatur lebih dari satu order
                              yang masuk, selain itu kamu juga mendapatkan
                              laporan cash flow yang ringkas dan jelas,
                              efisiensi tenaga kerja,analisis data pelanggan,
                              virtual royalty card, tingkat akurasi pesanan, dan
                              masih banyak lagi.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenDataPartner(!openDataPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Data apa saja yang harus saya siapkan untuk bisa bergabung menjadi UR Partner?" />
                        {openDataPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openDataPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              <ol>
                                <li>Nama usaha</li>
                                <li>Alamat tempat usaha</li>
                                <li>Nama pemilik usaha</li>
                                <li>No HP pemilik usaha</li>
                                <li>Email pemilik usaha</li>
                              </ol>
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenDemoPartner(!openDemoPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Saya ingin dilakukan demo terlebih dahulu di outlet saya, apakah bisa?" />
                        {openDemoPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openDemoPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tim kami siap untuk membantu demo di outlet anda,
                              dengan syarat dan ketentuan yang berlaku.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenPaketPartner(!openPaketPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bagaimana caranya berlangganan paket UR?" />
                        {openPaketPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openPaketPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kamu bisa membeli paket dengan cara menghubungi
                              Contact Center kami. Jangan lupa untuk melengkapi
                              data yang dibutuhkan disini.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenTrialPartner(!openTrialPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apa saja paket yang ditawarkan setelah free trial?" />
                        {openTrialPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openTrialPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Untuk paket, UR memberikan harga mulai dari
                              Rp.300.000/bulan. Jika membayar full satu tahun
                              per bulannya Rp.250.000.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenPembayaranPartner(!openPembayaranPartner)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Jika saya setuju menggunakan aplikasi UR, bagaimana untuk mekanisme pembayarannya?" />
                        {openPembayaranPartner ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={openPembayaranPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              <ol>
                                <li>
                                  Kami akan mengirimkan tagihan ke kamu, lalu
                                  kamu dapat melakukan pembayaran melalui
                                  transfer ke rekening UR. Setelah itu tim
                                  Support kami akan melakukan instalasi dan
                                  pelatihan sesuai dengan jadwal yang telah
                                  disepakati.
                                </li>
                                <li>
                                  Jika klien ingin melakukan pembayaran di
                                  tempat, harap dijadwalkan terlebih dahulu
                                  jadwal untuk instalasi dan pelatihan. Pada
                                  hari itu juga, kamu dapat melakukan pembayaran
                                  tunai kepada tim Support UR yang berkunjung.
                                </li>
                              </ol>
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenCabangPartner(!openCabangPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Jika saya memiliki cabang, apakah saya harus membeli paket UR lagi?" />
                        {openCabangPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openCabangPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Seluruh paket langganan yang kami tawarkan adalah
                              untuk pengunaan di satu outlet. Jika kamu ingin
                              menggunakan layanan yang sama di cabang lain, maka
                              kamu harus melakukan pembelian paket UR lagi untuk
                              outlet cabang lainnya.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenDataPartner(!openDataPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah UR Partner dapat dijalankan di sistem iOS/MacOS?" />
                        {openDataPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openDataPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Untuk saat ini hanya dapat dijalankan di tablet
                              dengan OS Android.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenHardwarePartner(!openHardwarePartner)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Saya butuh hardware untuk bisa memakai aplikasi UR Partner, apakah pihak UR menyediakan?" />
                        {openHardwarePartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openHardwarePartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Pihak kami belum menyediakan hardware dan kami
                              menyarankan menggunakan tablet android berukuran 8
                              inch dan disarankan tidak menggunakan smartphone.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenInstalasiPartner(!openInstalasiPartner)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah saya harus mengeluarkan uang untuk biaya instalasi dan pelatihan?" />
                        {openInstalasiPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openInstalasiPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kita memberikan instalasi dan prosedur teknis ,
                              apabila ada gangguan teknis maka tim kami akan
                              datang ke outlet. Khusus di luar Kota Bandung akan
                              dikenakan biaya akomodasi.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenWalletPartner(!openWalletPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah pihak UR dapat membantu proses pendaftaran e-Wallet?" />
                        {openWalletPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openWalletPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tentu bisa, kami membantu proses pendaftaran
                              hingga berjalan. Tetapi jangan lupa juga untuk
                              melihat syarat dan ketentuan pelengkapan dokumen
                              disini.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenPajakPartner(!openPajakPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah UR memiliki fitur untuk mengatur biaya pajak?" />
                        {openPajakPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openPajakPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Tentu bisa, kamu bisa mengatur biaya pajak.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() => setOpenMenuPartner(!openMenuPartner)}
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Apakah ada fitur menambah menu?" />
                        {openMenuPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openMenuPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Kita sudah sediakan fitur “Tambah Menu”
                              selanjutnya anda tinggal menginput nama, foto,
                              deskripsi dan harga.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>

                    <List aria-labelledby="nested-list-subheader">
                      <ListItem
                        button
                        onClick={() =>
                          setOpenMemantauPartner(!openMemantauPartner)
                        }
                      >
                        <ListItemIcon>
                          <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bagaimana jika saya memiliki banyak cabang, apakah saya bisa memantau data semua cabang hanya dengan menggunakan satu dashboard saja?" />
                        {openMemantauPartner ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={openMemantauPartner}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <Typography variant="subtitle1" gutterBottom>
                              Satu dashboard dapat digunakan untuk memantau
                              seluruh cabang yang dimiliki.
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  </TabPanel>
                </SwipeableViews>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Faq;
