import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RegisterCTA from "../../../components/buttons/RegisterCTA";
import RegularButton from "../../../components/buttons/RegularButton";
import * as React from "react";
import { Paper } from "@material-ui/core";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#5F0936" : "#5F0936",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((props) => ({
  hero: {
    // backgroundImage: `url(./asset/images/banner/selfOrderBg.jpg)`,
    backgroundColor: "#5F0936",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    margin: "none",
  },
  learnMore: {
    color: "black",
    fontWeight: "bold",
    borderRadius: "15px",
    "&:hover": {
      color: "black",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderRadius: "15px",
    },
  },
}));

export default function MultiDevice(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      <Box
        className={classes.hero}
        style={{
          height: isDesktop ? "100vh" : "100%",
        }}
      >
        <Grid
          container
          alignItems={isDesktop ? "left" : "center"}
          justify={isDesktop ? "flex-start" : "center"}
          spacing={2}
          direction={isDesktop ? "row" : "column-reverse"}
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                marginLeft: isDesktop ? "5%" : "0",
              }}
            >
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  marginTop: isDesktop ? "15%" : "35%",
                  fontWeight: "600",
                  textAlign: isDesktop ? "left" : "center",
                }}
              >
                Multi Device
              </Typography>
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: isDesktop ? 36 : 32,
                  textAlign: isDesktop ? "left" : "center",
                }}
              >
                UR Merchant dapat di pakai di berbagai platform seperti Android,
                iOS, dan Web
              </Typography>
            </div>
            <div style={{ marginTop: "30%" }}></div>
            <Grid
              container
              xs={12}
              alignItems={isDesktop ? "left" : "center"}
              justify={isDesktop ? "flex-start" : "center"}
              direction={isDesktop ? "row" : "column"}
              spacing={1}
              style={{
                marginTop: "20vh",
                marginLeft: isDesktop ? "5%" : "0",
              }}
            >
              <Grid item>
                <RegisterCTA width="35vh" height="6vh" fontSize="18" />
              </Grid>
              <Grid item>
                <Button
                  style={{
                    height: "6vh",
                    padding: props.padding ?? "30px",
                  }}
                  className={classes.learnMore}
                  href="/partner-features"
                >
                  <div
                    style={{
                      padding: "15px",
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "white",
                    }}
                  >
                    Pelajari Lebih Lanjut
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="./asset/images/banner/multidevice.png"
              height={600}
              style={{ marginTop: "15%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
