import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((props) => ({
  cta: {
    color: "white",
    backgroundColor: "#1fb0e6",
    fontWeight: "bold",
    borderRadius: "15px",
    "&:hover": {
      color: "white",
      backgroundColor: "#0096d5",
      fontWeight: "bold",
      // borderColor: "#de148c",
      // border: "3px solid",
      borderRadius: "15px",
    },
  },
}));

export default function RegularButton(props) {
  const classes = useStyles(props);

  return (
    <>
      {/* <Button style={{ width: props.width, height: props.height, padding: props.padding ?? "30px" }} className={classes.cta} variant="text" href="https://partner.ur-hub.com/landing?ref=25k&utm_source=company+profile&utm_medium=cta&utm_id=registration" target="_blank"> */}
      <Button
        style={{
          height: props.height,
          padding: props.padding ?? "30px",
        }}
        className={classes.cta}
        variant="text"
        href={props.href}
        target="_blank"
      >
        <div
          style={{
            padding: "15px",
            fontWeight: "bold",
            fontSize: Number(props.fontSize),
            color: props.fontColor,
          }}
        >
          {props.text}
        </div>
      </Button>
    </>
  );
}
