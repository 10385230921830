import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((props) => ({
  cta: {
    color: "white",
    backgroundColor: "#de148c",
    fontWeight: "bold",
    borderColor: "#de148c",
    borderRadius: "15px",
    border: "3px solid",
    "&:hover": {
      color: "white",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderColor: "#de148c",
      border: "3px solid",
      borderRadius: "15px",
    },
  },
}));

export default function RegisterCTA(props) {
  const classes = useStyles(props);

  return (
    <>
      {/* <Button style={{ width: props.width, height: props.height, padding: props.padding ?? "30px" }} className={classes.cta} variant="text" href="https://partner.ur-hub.com/landing?ref=25k&utm_source=company+profile&utm_medium=cta&utm_id=registration" target="_blank"> */}
      <Button
        style={{
          width: props.width,
          height: props.height,
          padding: props.padding ?? "30px",
        }}
        className={classes.cta}
        variant="text"
        href="https://partner.ur-hub.com/landing?utm_source=company+profile&utm_medium=cta&utm_id=registration"
        target="_blank"
      >
        <div
          style={{
            padding: "5px",
            fontWeight: "bold",
            fontSize: Number(props.fontSize),
            color: props.fontColor,
          }}
        >
          Coba Gratis
        </div>
      </Button>
    </>
  );
}
