import { Box, Button, Card, CardContent, Grid, Snackbar, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { deleteEmployee, deleteUser } from "../../../action/account";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function DeleteAccount(){
  const params = useParams();

  const [partnerId, _] = useState(params.partnerId ?? 0)
  const [email, setEmail] = useState();
  const [formValid, setFormValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const alertDuration = 5000

  function validateEmail(email) {
    let re = /^\S+@\S+\.\S+$/;
    return re.test(String(email).toLowerCase())
  }


  function handleSubmit(){
    if (validateEmail(email)){
      if (partnerId == 0){
        deleteUser(email).then( response => {
          setOpenAlert(true)
          setAlertSeverity(response.success == 1 ? 'success' : 'error')
          setAlertMessage(response.msg)
        })
      } else {
        deleteEmployee(email, partnerId).then( response => {
          setOpenAlert(true)
          setAlertSeverity(response.success == 1 ? 'success' : 'error')
          setAlertMessage(response.msg)
        })
      }
    } else {
      setFormValid(false)
      setErrorMessage('Email tidak valid')
    }
  }

  function handleCloseAlert(){
    setOpenAlert(false)
  }

  return (
    <div>
      <Snackbar open={openAlert} autoHideDuration={alertDuration} onClose={handleCloseAlert}>
        <Alert severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box mt={10} alignContent={'center'}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{
            height: '70vh'
          }}
          >
          <Grid
            item
          >
            <Card 
              variant="outlined" 
              style={{
                minWidth: 300, 
                margin: 15
              }}
            >
              <CardContent>
                <Typography gutterBottom>
                  {`Hapus Akun`}
                </Typography>
                <form>
                  <TextField
                    required
                    fullWidth
                    type="email"
                    label='Email'
                    className="mb-3"
                    value={email}
                    error={!formValid}
                    helperText={errorMessage}
                    onChange={(e) => {
                      setFormValid(true)
                      setErrorMessage('')
                      setEmail(e.target.value)
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    <Typography>
                      {`Hapus Akun`}
                    </Typography>
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}