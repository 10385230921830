import React from "react";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createTheme";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

require("dotenv").config();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#de148c",
      light: "#de148c",
      dark: "#de148c",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#1fb0e6",
      main: "#1fb0e6",
      dark: "#1fb0e6",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    blue: {
      main: "#1fb0e6",
      light: "#1fb0e6",
      dark: "#1fb0e6",
    },
    grey: {
      main: "#1fb0e6",
      light: "#1fb0e6",
      dark: "#1fb0e6",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    button: {
      textTransform: "none",
    },
    h1: {
      fontWeight: "700",
    },
    h2: {
      fontWeight: "700",
    },
    h3: {
      fontWeight: "700",
    },
    h4: {
      fontWeight: "700",
    },
    h5: {
      fontWeight: "700",
    },
    h6: {
      fontWeight: "700",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
