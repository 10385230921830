import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
}));

function SyaratDanKetentuan() {
  const classes = useStyles();
  const [openDataIntegrity, setOpenDataIntegrity] = React.useState(false);
  const [openPrivacyPoilcy, setOpenPrivacyPoilcy] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openChoiceOfLaw, setOpenChoiceOfLaw] = React.useState(false);
  const [tnc5, setTnc5] = React.useState(false);
  const [tnc6, setTnc6] = React.useState(false);
  const [tnc7, setTnc7] = React.useState(false);
  const [tnc8, setTnc8] = React.useState(false);

  return (
    <>
      <MetaTags>
        <title>Syarat dan Ketentuan | UR - Self Order & POS App</title>
        <meta name="title" content="Syarat dan Ketentuan" />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Syarat dan Ketentuan" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Syarat dan Ketentuan" />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Syarat dan Ketentuan
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/tc.png"
                  alt="tc"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box pt={15}>
          <Grid container justify="center" className={classes.box}>
            <Grid item xs={10}>
              {/* <Typography variant="subtitle1" gutterBottom>
                This website is own and managed by PT. Rahmat Tuhan Lestari, and
                this terms and conditions (“Terms”, “Agreement”) apply to
                visitors and users of UR The term “us”, “we” or “our”) refers to
                UR, and the term “User”, “you” or “your” refers to the user who
                accept this Terms and Conditions. The use of this website shall
                indicate your acceptance to these conditions, do not use this
                site if you do not agree to be bound by these terms.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                If you are below 18 years old: you must obtain consent from your
                parent(s) or legal guardian(s), their acceptance of these Terms
                and Conditions and their agreement to take responsibility for:
                your actions, any charges associated with your use of any of the
                Services or purchase of Products; and your acceptance and
                compliance with these Terms of Use. If you do not have consent
                from your parent(s) or legal guardian(s), you must stop
                using/accessing this website and using the Servicesx
              </Typography> */}

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenDataIntegrity(!openDataIntegrity)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Definisi" />
                  {openDataIntegrity ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openDataIntegrity} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            <a class="namaPT"></a> adalah suatu perseroan
                            terbatas yang menjalankan kegiatan usaha jasa web
                            portal{" "}
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>{" "}
                            <a class="tulisanMerah">
                              dan Aplikasi UR POS AND SELF ORDER
                            </a>
                            , yakni situs{" "}
                            <a class="tulisanMerah">dan aplikasi</a> pencarian
                            toko dan Barang yang dijual oleh penjual terdaftar.
                            Selanjutnya disebut UR.
                          </li>
                          <li class="isiSubJudul">
                            Situs UR adalah{" "}
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>
                            .
                          </li>
                          <li class="isiSubJudul">
                            Syarat & ketentuan adalah perjanjian antara Pengguna
                            dan UR yang berisikan seperangkat peraturan yang
                            mengatur hak kewajiban, tanggung jawab pengguna dan
                            UR, serta tata cara penggunaan sistem layanan UR.
                          </li>
                          <li class="isiSubJudul">
                            Pengguna adalah pihak yang menggunakan layanan UR,
                            termasuk namun tidak terbatas pada pembeli, penjual
                            maupun pihak lain yang sekedar berkunjung ke Situs
                            UR.
                          </li>
                          <li class="isiSubJudul">
                            Pembeli adalah Pengguna terdaftar yang melakukan
                            permintaan atas Barang yang dijual oleh Penjual di
                            Situs UR.
                          </li>
                          <li class="isiSubJudul">
                            Penjual adalah Pengguna terdaftar yang melakukan
                            tindakan buka toko dan/atau melakukan penawaran atas
                            suatu Barang kepada para Pengguna Situs UR.
                          </li>
                          <li class="isiSubJudul">
                            Barang adalah benda yang berwujud / memiliki fisik.
                            Barang yang dapat diantar / memenuhi kriteria
                            pengiriman oleh perusahaan jasa pengiriman Barang.
                          </li>
                          <li class="isiSubJudul">
                            Feed adalah fitur pada Situs/Aplikasi UR yang
                            menampilkan konten dari Penjual, atau pihak lainnya
                            terkait Barang tertentu.
                          </li>
                          <li class="isiSubJudul">
                            Key Opinion Leaders atau KOL adalah pihak yang
                            mempromosikan Barang atau Penjual tertentu melalui
                            Feed.
                          </li>
                          <li class="isiSubJudul">
                            Member Resmi UR adalah Member bersama yang
                            disepakati oleh UR dan para pengguna untuk proses
                            transaksi jual beli di Situs UR. Member resmi UR
                            dapat ditemukan di halaman{" "}
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>
                            .
                          </li>
                          <li class="isiSubJudul">
                            Internal Dropshipper adalah transaksi yang
                            seluruhnya terjadi di ruang lingkup UR yang
                            dilakukan oleh Penjual dengan membeli Barang dari
                            Penjual lainnya dan menjualnya kembali melalui Situs
                            UR.
                          </li>
                          <li class="isiSubJudul">
                            UR adalah aplikasi mobile/pengguna (“
                            <a class="namaPT"></a>”) yang dapat diunduh
                            (download) oleh User dari media distribusi
                            aplikasi/software resmi yang ditunjuk UR dapat
                            diakses secara langsung oleh User melalui gawai
                            (gadget).
                          </li>
                          <li class="isiSubJudul">
                            PIN (Personal Identification Number) adalah nomor
                            identifikasi pribadi bagi User yang menggunakan UR.
                            User dapat menggunakan PIN untuk melakukan transaksi
                            finansial pada UR.
                          </li>
                          <li class="isiSubJudul">
                            Password adalah kombinasi deretan huruf dan angka
                            (alfanumerik) yang dibuat User pada saat pembukaan
                            Member UR dan digunakan sebagai salah satu kode
                            keamanan untuk mengakses UR.
                          </li>
                          <li class="isiSubJudul">
                            Kode Transaksi adalah suatu kode yang dihasilkan
                            oleh UR untuk melakukan transaksi penjual dan
                            pembeli.
                          </li>
                          <li class="isiSubJudul">
                            SMS (Short Message Service) adalah layanan
                            penyampaian pesan singkat dalam bentuk teks dan/atau
                            angka yang dapat diterima dan/atau dikirimkan
                            melalui handphone.
                          </li>
                          <li class="isiSubJudul">
                            Operator Seluler adalah perusahaan yang menyediakan
                            layanan jaringan telepon seluler.
                          </li>
                          <li class="isiSubJudul">
                            Quick Response Code atau QR Code adalah kode dua
                            dimensi yang terdiri atas penanda tiga pola persegi
                            pada sudut kiri bawah, sudut kiri atas, dan sudut
                            kanan atas, memiliki modul hitam berupa persegi
                            titik atau piksel, dan memiliki kemampuan menyimpan
                            data alfanumerik, karakter, dan symbol.
                          </li>
                          <li class="isiSubJudul">
                            QR Code untuk Pembayaran atau QR Code Pembayaran
                            adalah QR Code yang digunakan untuk memfasilitasi
                            transaksi pembayaran nirsentuh melalui pemindaian.
                          </li>
                          <li class="isiSubJudul">
                            Standar Nasional QR Code Pembayaran (Quick Response
                            Code Indonesian Standard) atau QRIS adalah standar
                            QR Code Pembayaran yang ditetapkan oleh Bank
                            Indonesia untuk digunakan dalam memfasilitasi
                            transaksi pembayaran di Negara Kesatuan Republik
                            Indonesia.
                          </li>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenPrivacyPoilcy(!openPrivacyPoilcy)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ketentuan Umum" />
                  {openPrivacyPoilcy ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openPrivacyPoilcy} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            Ketentuan Penggunaan ini adalah perjanjian antara
                            pengguna (“Anda”) dan <a class="namaPT"></a>{" "}
                            (“Kami”), sebuah perseroan terbatas yang didirikan
                            dan beroperasi secara sah berdasarkan hukum negara
                            Republik Indonesia dan berdomisili di Bandung Jawa
                            barat, Indonesia. Ketentuan Penggunaan ini mengatur
                            akses dan penggunaan Anda atas aplikasi, situs web (
                            <a class="webUR" href="https://ur-hub.com/">
                              www.ur-hub.com
                            </a>{" "}
                            dan situs web lain yang Kami kelola), konten dan
                            produk yang disediakan oleh Kami (selanjutnya,
                            secara bersama-sama disebut sebagai “Aplikasi"),
                            serta pemesanan, pembayaran atau penggunaan layanan
                            yang tersedia pada Aplikasi ("Layanan").
                          </li>
                          <li class="isiSubJudul">
                            Dengan menyetujui Ketentuan Penggunaan ini, Anda
                            juga menyetujui Ketentuan Penggunaan tambahan,
                            termasuk Ketentuan Penggunaan pada setiap Layanan,
                            berikut biaya layanan yang terkait dan perubahannya
                            yang merupakan bagian yang tidak terpisahkan dari
                            Ketentuan Penggunaan ini (selanjutnya, Ketentuan
                            Penggunaan, Ketentuan Penggunaan tambahan, dan
                            perubahannya secara bersama-sama disebut sebagai
                            “Ketentuan Penggunaan”). Meskipun merupakan satu
                            kesatuan, Ketentuan Penggunaan tambahan akan berlaku
                            dalam hal terdapat perbedaan dengan Ketentuan
                            Penggunaan.
                          </li>
                          <li class="isiSubJudul">
                            User merupakan perorangan dan Warga Negara Indonesia
                            (“WNI”) yang telah memiliki Kartu Tanda Penduduk
                            elektronik (KTP-el) sesuai ketentuan hukum yang
                            berlaku di Negara Kesatuan Republik Indonesia. Untuk
                            dapat menggunakan UR, User harus memiliki SIM Card
                            Operator Seluler.
                          </li>
                          <li class="isiSubJudul">
                            User wajib melakukan perubahan data secara berkala
                            atau apabila terdapat perubahan data User melalui
                            UR. Perubahan data User tidak dapat dilakukan
                            sepanjang Member User berstatus Dormant, kecuali
                            Member tersebut telah dilakukan pengaktifan kembali.
                          </li>
                          <li class="isiSubJudul">
                            e. User dengan ini memahami segala risiko yang
                            mungkin timbul akibat kelalaian User memperbaharui
                            data User, termasuk namun tidak terbatas pada risiko
                            kerugian baik finansial maupun nonfinansial yang
                            mungkin terjadi dan dengan ini membebaskan{" "}
                            <a class="namaPT"></a> dari segala gugatan/tuntutan
                            dari pihak manapun.
                          </li>
                          <li class="isiSubJudul">
                            User harus menghubungi Operator Seluler yang
                            bersangkutan untuk penanganan masalah yang berkaitan
                            dengan SIM Card, jaringan Operator Seluler, jaringan
                            internet pada handphone, tagihan penggunaan dari
                            Operator Seluler, biaya SMS, dan valve added service
                            Operator Seluler.
                          </li>
                          <li class="isiSubJudul">
                            Data User pada UR akan disimpan{" "}
                            <a class="namaPT"></a> sesuai ketentuan retensi yang
                            berlaku.
                          </li>
                          <li class="isiSubJudul">
                            User dengan ini menyatakan bahwa spesimen tanda
                            tangan User adalah tanda tangan sebagaimana
                            tercantum pada KTP-e User yang direkam (capture) dan
                            diunggah (upload) oleh User pada
                            sistem/platform/aplikasi UR milik{" "}
                            <a class="namaPT"></a>
                            (“Spesimer”).
                          </li>
                          <li class="isiSubJudul">
                            Dalam rangka pemeliharaan dan/atau pembaharuan
                            sistem untuk menjaga dan/atau meningkatan kualitas
                            layanan penggunaan UR, <a class="namaPT"></a> berhak
                            untuk menghentikan layanan penggunaan UR untuk
                            sementara waktu dengan pemberitahuan terlebih dahulu
                            kepada User dan User memahami segala akibat dari
                            penghentian layanan tersebut, termasuk namun tidak
                            terbatas pada akibat tidak dapat dilakukannya
                            transaksi perbankan melalui UR.
                          </li>
                          <li class="isiSubJudul">
                            <a class="namaPT"></a> tidak mengijinkan untuk di
                            gunakan bagai toko atau transaksi sebagai berikut,
                            yang berkaitan dengan kegiatan illegal, narkoba,
                            penjual belian Senjata Api/Senjata Tajam, kegiatan
                            asusila, penjualan barang porno/seks dan hal-hal
                            yang berkaitan dengan kejahatan dan hukum yang
                            berlaku dan norma-norma kemasarakyatan. Jika
                            diketahui atau mendapat laporan,{" "}
                            <a class="namaPT"></a> berhak untuk memblokir akun
                            tersebut dan melapor kepada pihak yang berwajib.
                          </li>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setOpenPurchase(!openPurchase)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Penggunaan Aplikasi" />
                  {openPurchase ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openPurchase} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            <h5>general</h5>
                            <ul>
                              <li class="subJudul2">
                                Akses dan penggunaan Aplikasi tunduk pada
                                Ketentuan Penggunaan ini.
                              </li>
                              <li class="subJudul2">
                                Anda mempunyai kebebasan penuh untuk memilih
                                menggunakan Aplikasi atau aplikasi lain,
                                menggunakan Layanan yang tersedia pada Aplikasi
                                atau tidak, atau berhenti menggunakan Aplikasi.
                              </li>
                              <li class="subJudul2">
                                Kami hanya memfasilitasi Anda untuk menemukan
                                berbagai Layanan yang Anda perlukan dengan
                                menyediakan Aplikasi. Semua Layanan disediakan
                                secara langsung oleh pihak ketiga independen
                                yang setuju menjadi penyedia layanan Kami dengan
                                skema kemitraan atau skema lainnya (“Penyedia
                                Layanan”).
                              </li>
                              <li class="subJudul2">
                                Ketika memesan Layanan, Aplikasi akan
                                menghubungkan Anda dengan Penyedia Layanan yang
                                tersedia di sekitar lokasi Anda. Dengan
                                demikian, beberapa Layanan tidak dapat digunakan
                                bila Anda tidak mengaktifkan fitur penentuan
                                lokasi.
                              </li>
                              <li class="subJudul2">
                                Selanjutnya, jika Penyedia Layanan menerima
                                pesanan Anda, Kami akan menginformasikan status
                                pesanan Anda melalui Aplikasi.
                              </li>
                              <li class="subJudul2">
                                Perintah/instruksi yang diberikan oleh User
                                melalui UR hanya dapat dilakukan dengan
                                menggunakan nomor handphone User yang telah
                                terdaftar berikut untuk pembayaran lewat ewllet
                                wajib menggunakan no hp yang sama dengan
                                applikasi UR.
                              </li>
                              <li class="subJudul2">
                                User wajib memastikan ketersediaan dana pada
                                Member User sebelum melakukan transaksi melalui
                                UR. User harus mengisi semua data yang
                                dibutuhkan untuk setiap transaksi secara benar
                                dan lengkap.
                              </li>
                              <li class="subJudul2">
                                Sebagai tanda persetujuan, User wajib memasukkan
                                PIN dan/atau melakukan Face Recognition untuk
                                melakukan transaksi finansial dan transaksi
                                lainnya yang ditentukan oleh{" "}
                                <a class="namaPT"></a>.
                              </li>
                              <li class="subJudul2">
                                Setiap instruksi dari User yang tersimpan pada
                                sistem dan/atau pusat data{" "}
                                <a class="namaPT"></a> merupakan data yang benar
                                dan mengikat User, serta merupakan bukti yang
                                sah atas instruksi dari User kepada{" "}
                                <a class="namaPT"></a>
                                untuk melakukan transaksi yang dimaksud, kecuali
                                User dapat membuktikan sebaliknya.
                              </li>
                              <li class="subJudul2">
                                <a class="namaPT"></a> menerima dan menjalankan
                                setiap instruksi dari User sebagai instruksi
                                yang sah berdasarkan penggunaan nomor handphone
                                dan PIN dan/atau Kode Transaksi,{" "}
                                <a class="namaPT"></a> tidak mempunyai kewajiban
                                untuk meneliti atau menyelidiki keaslian maupun
                                keabsahan atau kewenangan pengguna nomor
                                handphone dan PIN dan/atau Kode Transaksi atau
                                menilai maupun membuktikan ketepatan maupun
                                kelengkapan instruksi dimaksud. Oleh karena itu,
                                instruksi tersebut adalah sah dan mengikat User
                                secara hukum.
                              </li>
                              <li class="subJudul2">
                                User wajib melakukan peningkatan versi (upgrade)
                                UR atas permintaan <a class="namaPT"></a>.
                              </li>
                              <li class="subJudul2">
                                Kelalaian User dalam melakukan peningkatan versi
                                (upgrade) UR mengakibatkan User tidak dapat
                                menggunakan UR atau hanya dapat mengakses fitur
                                tertentu pada UR.
                              </li>
                              <li class="subJudul2">
                                Untuk setiap instruksi dari User atas transaksi
                                finansial yang berhasil dilakukan oleh{" "}
                                <a class="namaPT"></a>, User akan mendapatkan
                                bukti transaksi yang akan tersimpan di aplikasi
                                UR sebagai bukti bahwa transaksi tersebut telah
                                dijalankan oleh <a class="namaPT"></a>.
                              </li>
                              <li class="subJudul2">
                                User wajib dan bertanggung jawab untuk
                                memastikan ketepatan dan kelengkapan instruksi
                                transaksi melalui UR yang dikirim kepada{" "}
                                <a class="namaPT"></a>. <a class="namaPT"></a>{" "}
                                tidak bertanggung jawab terhadap segala akibat
                                apapun yang timbul karena ketidaklengkapan,
                                ketidakjelasan data, atau ketidaktepatan
                                instruksi dari User.
                              </li>
                              <li class="subJudul2">
                                Catatan, tape/cartridge, hasil print out
                                komputer, Salinan, atau bentuk penyimpanan
                                informasi atau data lain yang terdapat pada{" "}
                                <a class="namaPT"></a> merupakan alat bukti yang
                                sah dan mengikat atas instruksi dari User yang
                                dijalankan oleh <a class="namaPT"></a>.
                              </li>
                              <li class="subJudul2">
                                User menyetujui dan mengakui keabsahan,
                                kebenaran, atau keaslian bukti instruksi dan
                                komunikasi yang dikirim secara elektronik oleh{" "}
                                <a class="namaPT"></a>, termasuk dokumen dalam
                                bentuk catatan komputer atau bukti transaksi
                                yang dijalankan oleh <a class="namaPT"></a>,
                                salinan, atau bentuk penyimpanan informasi yang
                                lain yang terdapat pada <a class="namaPT"></a>.
                                Semua sarana dan/atau dokumen tersebut merupakan
                                satu-satunya alat bukti yang sah dan mengikat
                                atas transaksi-transaksi perbankan yang
                                dilakukan oleh User melalui UR, kecuali User
                                dapat membuktikan sebaliknya.
                              </li>
                              <li class="subJudul2">
                                Dengan melakukan transaksi melalui UR, User
                                mengakui semua komunikasi dan instruksi dari
                                User yang diterima <a class="namaPT"></a> akan
                                diperlakukan sebagai alat bukti yang sah
                                meskipun dokumen tidak dibuat secara tertulis
                                dan/atau dokumen tidak ditandatangani oleh User
                                dan <a class="namaPT"></a>.
                              </li>
                              <li class="subJudul2">
                                <a class="namaPT"></a> berhak untuk menentukan
                                dan mengubah besarnya limit untuk suatu
                                transaksi yang akan diberitahukan oleh{" "}
                                <a class="namaPT"></a> dalam bentuk dan melalui
                                media/sarana yang tersedia pada{" "}
                                <a class="namaPT"></a> sesuai dengan ketentuan
                                hukum yang berlaku.
                              </li>
                              <li class="subJudul2">
                                Operator Seluler berhak mengenakan biaya, antara
                                lain namun tidak terbatas untuk biaya pengiriman
                                SMS kepada User untuk setiap transaksi, baik
                                yang berhasil maupun yang tidak berhasil
                                dilakukan.
                              </li>
                              <li class="subJudul2">
                                Saat User melakukan transaksi QRIS, User wajib
                                memastikan kebenaran nama merchant dan nominal
                                transaksi QRIS. User bertanggung jawab
                                sepenuhnya atas segala akibat yang timbul
                                sehubungan dengan transaksi QRIS yang dilakukan
                                User, termasuk namun tidak terbatas kerugian
                                yang timbul karena kelalaian User dalam
                                memastikan kebenaran nama merchant dan nominal
                                transaksi pembayaran.
                              </li>
                            </ul>
                          </li>
                          <li class="isiSubJudul">
                            <h5>akun</h5>
                            <ul>
                              <li class="subJudul2">
                                Apabila Kami mengetahui atau mempunyai alasan
                                yang cukup untuk menduga bahwa Anda telah
                                melakukan tindakan asusila, pelanggaran,
                                kejahatan atau tindakan lain yang bertentangan
                                dengan Ketentuan Penggunaan ini dan/atau
                                peraturan perundang-undangan yang berlaku, baik
                                yang dirujuk dalam Ketentuan Penggunaan ini atau
                                tidak, maka Kami berhak untuk dan dapat
                                membekukan Akun, baik sementara atau permanen,
                                atau menghentikan akses Anda terhadap Aplikasi,
                                termasuk Layanan, Konten Pihak Ketiga, Penawaran
                                dan/atau Metode Pembayaran yang terdapat di
                                dalamnya, melakukan pemeriksaan, menuntut ganti
                                kerugian, melaporkan kepada pihak berwenang
                                dan/atau mengambil tindakan lain yang kami
                                anggap perlu, termasuk tindakan hukum pidana
                                maupun perdata.
                              </li>
                              <li class="subJudul2">
                                Kami akan menindaklanjuti dengan melakukan
                                investigasi dan/atau memfasilitasi Penyedia
                                Layanan yang bersangkutan untuk melaporkan
                                kepada pihak yang berwajib apabila Kami menerima
                                Laporan adanya pelanggaran yang Anda lakukan
                                atas Ketentuan Penggunaan ini ataupun
                                pelanggaran terhadap peraturan
                                perundang-undangan yang berlaku, sehubungan
                                dengan:
                              </li>
                              <ul>
                                <li class="subJudul3">
                                  Pelecehan atau kekerasan verbal, termasuk
                                  namun tidak terbatas pada, atas fisik, jenis
                                  kelamin, suku, agama dan ras, yang Anda
                                  lakukan terhadap Penyedia Layanan; Penggunaan
                                  Aplikasi pada perangkat rooted atau
                                  jail-broken; Pelecehan atau kekerasan fisik
                                  yang Anda lakukan terhadap Penyedia Layanan:
                                  dan/atau
                                </li>
                                <li class="subJudul3">Tindakan penipuan;</li>
                                <li class="subJudul3">
                                  Pelecehan atau kekerasan seksual, baik secara
                                  verbal maupun fisik, yang Anda lakukan
                                  terhadap Penyedia Layanan;
                                </li>
                                <li class="subJudul3">
                                  Ketentuan ini juga berlaku sebaliknya apabila
                                  Anda mengalami tindakan sebagaimana disebutkan
                                  di atas yang dilakukan oleh Penyedia Layanan
                                  terhadap Anda.
                                </li>
                                <li class="subJudul3">
                                  UR akan diblokir jika User melakukan hal-hal
                                  sebagai berikut:
                                </li>
                                <ul>
                                  <li class="subJudul4">
                                    Salah memasukkan PIN UR sebanyak 3 (tiga)
                                    kali berturut-turut;
                                  </li>
                                  <li class="subJudul4">
                                    Salah memasukkan Password UR sebanyak 3
                                    (tiga) kali berturut-turut;
                                  </li>
                                  <li class="subJudul4">
                                    Mengajukan permohonan pemblokiran melalui
                                    URSolution.
                                  </li>
                                </ul>
                                <li class="subJudul3">
                                  Apabila terjadi pemblokiran UR akibat
                                  kesalahan PIN sebanyak 3 (tiga) kali
                                  berturut-turut, User harus melakukan reset PIN
                                  menggunakan kombinasi password, face
                                  recognition, dan/atau data Nomor Induk
                                  Kependudukan (NIK) KTP-el dan tanggal lahir.
                                  Jika terjadi kendala dalam proses reset PIN,
                                  maka User dapat menghubungi haloUR.
                                </li>
                                <li class="subJudul3">
                                  Apabila terjadi pemblokiran UR akibat
                                  kesalahan Password sebanyak 3 (tiga) kali
                                  berturut-turut, User harus melakukan reset
                                  Password menggunakan kombinasi PIN, face
                                  recognition, dan/atau data NIK KTP-el dan
                                  tanggal lahir. Jika terjadi kendala dalam
                                  proses reset Password, maka User dapat
                                  menghubungi URSolution.
                                </li>
                                <li class="subJudul3">
                                  Syarat & ketentuan mungkin diubah dan/atau
                                  diperbaharui dari waktu ke waktu tanpa
                                  pemberitahuan sebelumnya. UR menyarankan agar
                                  anda membaca secara seksama dan memeriksa
                                  halaman Syarat & ketentuan ini dari waktu ke
                                  waktu untuk mengetahui perubahan apapun.
                                  Dengan tetap mengakses dan menggunakan layanan
                                  UR, maka pengguna dianggap menyetujui
                                  perubahan-perubahan dalam Syarat & Ketentuan.
                                </li>
                                <li class="subJudul3">
                                  Biaya Layanan adalah biaya yang dikenakan
                                  kepada Pengguna untuk tujuan pemeliharaan
                                  sistem dan peningkatan layanan dalam
                                  bertransaksi melalui Situs UR dan ketentuan
                                  lebih lanjut mengenai biaya layanan dapat
                                  dilihat <a href="">DISINI</a>.
                                </li>
                                <li class="subJudul3">
                                  Sebelum menggunakan Aplikasi, Anda harus
                                  menyetujui Ketentuan Penggunaan ini dan
                                  Kebijakan Privasi, dan mendaftarkan diri Anda
                                  dengan memberikan informasi yang dibutuhkan
                                  oleh Kami. Saat melakukan pendaftaran, Kami
                                  akan meminta Anda untuk memberikan nama
                                  lengkap, alamat email, dan nomor telepon
                                  seluler pribadi Anda yang sah. Anda dapat
                                  mengubah informasi data diri Anda pada fitur
                                  pengaturan dalam Aplikasi.
                                </li>
                                <li class="subJudul3">
                                  Setelah melakukan pendaftaran, sistem Kami
                                  akan menghasilkan kode verifikasi secara
                                  otomatis dan mengirim kode verifikasi tersebut
                                  melalui pesan singkat ke nomor telepon genggam
                                  yang Anda berikan. Anda perlu melakukan
                                  verifikasi dengan memasukan kode verifikasi
                                  tersebut pada halaman pendaftaran di Aplikasi.
                                </li>
                                <li class="subJudul3">
                                  Setelah melakukan verifikasi, sistem Kami akan
                                  membuatkan akun UR pribadi (“Akun”) untuk Anda
                                  yang dapat digunakan untuk menggunakan
                                  Aplikasi dan memesan Layanan melalui Aplikasi.
                                  Nomor telepon genggam Anda melekat pada Akun
                                  Anda sehingga Anda tidak bisa membuat Akun
                                  baru dengan nomor telepon genggam yang sudah
                                  didaftarkan. Hal yang sama juga berlaku
                                  apabila di kemudian hari Anda mengubah nomor
                                  telepon genggam Anda pada menu pengaturan di
                                  Aplikasi.
                                </li>
                                <li class="subJudul3">
                                  Dalam hal Anda telah keluar dari Akun Anda,
                                  maka Anda perlu memasukan alamat surat
                                  elektronik atau nomor telepon genggam yang ada
                                  berikan pada saat mendaftarkan diri Anda dan
                                  memasukan kode verifikasi, yang kemudian
                                  dikirim secara otomatis oleh sistem Kami ke
                                  nomor telepon genggam terdaftar Anda, pada
                                  halaman pengaksesan.
                                </li>
                                <li class="subJudul3">
                                  KODE VERIFIKASI (ONE TIME PASSWORD/OTP)
                                  DIHASILKAN SECARA OTOMATIS OLEH SISTEM KAMI.
                                  KAMI TIDAK MENGETAHUI DAN TIDAK PERNAH MEMINTA
                                  KODE VERIFIKASI ANDA. JANGAN PERNAH
                                  MEMBERITAHUKAN KODE VERIFIKASI ANDA KEPADA
                                  SIAPAPUN BAHKAN KEPADA KAMI ATAU PIHAK LAIN
                                  YANG MENGAKU SEBAGAI PERWAKILAN KAMI.
                                </li>
                              </ul>
                            </ul>
                          </li>
                          <li class="isiSubJudul">
                            <h5>transaksi</h5>
                            <ul>
                              <li class="subJudul2">
                                Kami atau pihak lain yang bekerja sama dengan
                                Kami dapat menyediakan Konten Pihak Ketiga yang
                                dapat Anda temukan pada Aplikasi. Dalam hal
                                Konten Pihak Ketiga disediakan oleh pihak lain
                                yang bekerja sama dengan Kami (“Penyedia Konten
                                Pihak Ketiga”), Kami tidak bertanggung jawab
                                atas bagian apapun dari isi Konten Pihak Ketiga.
                                Akses atau penggunaan Anda terhadap Konten Pihak
                                Ketiga tersebut merupakan bentuk persetujuan
                                Anda untuk tunduk pada syarat dan ketentuan yang
                                ditetapkan oleh Kami atau Penyedia Konten Pihak
                                Ketiga, termasuk terhadap Kebijakan Privasi Kami
                                atau Penyedia Konten Pihak Ketiga.
                              </li>
                              <li class="subJudul2">
                                Konten Pihak Ketiga adalah setiap dan/atau
                                seluruh informasi dan penawaran barang dan/atau
                                jasa, yang dibuat dan/atau disusun dan/atau
                                dikembangkan dan/atau dikelola oleh Penyedia
                                Konten Pihak Ketiga termasuk namun tidak
                                terbatas pada teks atau tulisan, gambar, quotes
                                atau kutipan, foto, ilustrasi, animasi, video,
                                rekaman suara atau musik, judul, deskripsi
                                dan/atau setiap data dalam bentuk apapun yang
                                disediakan oleh Penyedia Konten Pihak Ketiga
                                untuk ditampilkan pada Aplikasi UR, termasuk
                                setiap tautan yang menghubungkan kepadanya.
                                Untuk menghindari keraguan, Konten Pihak Ketiga
                                mencakup pula setiap dan/atau seluruh penawaran
                                barang dan/atau jasa, informasi, data, berita
                                aktual, tulisan, gambar, kutipan, foto,
                                ilustrasi, animasi, video, rekaman suara, yang
                                diperoleh Penyedia Konten Pihak Ketiga dari
                                pihak ketiga, dimana Penyedia Konten Pihak
                                Ketiga telah memiliki kewenangan untuk
                                menggunakan dan mendistribusikan konten
                                tersebut.
                              </li>
                              <li class="subJudul2">
                                Kami atau pihak lain yang bekerja sama dengan
                                Kami dapat memberikan penawaran atau promosi
                                (“Penawaran”) yang dapat ditukar dengan barang,
                                Layanan atau manfaat lain terkait dengan
                                penggunaan Aplikasi. Dalam hal Penawaran
                                disediakan oleh pihak lain yang bekerja sama
                                dengan Kami (“Penyedia Penawaran”), Kami tidak
                                bertanggung jawab atas bagian apapun dari isi
                                Penawaran tersebut. Akses atau penggunaan Anda
                                terhadap Penawaran merupakan bentuk persetujuan
                                Anda untuk tunduk pada syarat dan ketentuan yang
                                ditetapkan oleh Kami atau Penyedia Penawaran,
                                termasuk terhadap Kebijakan Privasi Kami atau
                                Penyedia Penawaran.
                              </li>
                              <li class="subJudul2">
                                Semua informasi, Penawaran yang terdapat pada
                                Aplikasi hanya dimaksudkan untuk memberikan Anda
                                pengalaman terbaik ketika menggunakan Aplikasi
                                atau Layanan. Anda tidak boleh menyalahgunakan
                                Penawaran yang Anda terima selama penggunaan
                                Aplikasi atau Layanan.
                              </li>
                              <li class="subJudul2">
                                Anda setuju untuk menggunakan Penawaran tersebut
                                sesuai dengan syarat, ketentuan, dan maksud dari
                                pemberian Penawaran dan tidak akan
                                menyalahgunakan, menggandakan, menguangkan,
                                mengalihkan, menggunakan untuk kepentingan
                                komersial atau mengambil keuntungan dengan tidak
                                wajar dari promosi tersebut dengan bentuk atau
                                cara apapun.
                              </li>
                              <li class="subJudul2">
                                Anda memahami bahwa Penawaran tidak dapat
                                ditukar dengan uang tunai, memiliki masa
                                keberlakuan yang terbatas dan tunduk pada
                                ketentuan yang berlaku untuk setiap Penawaran
                                tersebut.
                              </li>
                              <li class="subJudul2">
                                Pembeli wajib bertransaksi melalui prosedur
                                transaksi yang telah ditetapkan oleh UR. Pembeli
                                melakukan pembayaran dengan menggunakan metode
                                pembayaran yang sebelumnya telah dipilih oleh
                                Pembeli, dan kemudian UR akan meneruskan dana ke
                                pihak Penjual apabila tahapan transaksi jual
                                beli pada sistem UR telah selesai.
                              </li>
                              <li class="subJudul2">
                                Saat melakukan pembelian Barang, Pembeli
                                menyetujui bahwa:
                              </li>
                              <ul>
                                <li class="subJudul3">
                                  Pembeli bertanggung jawab untuk membaca,
                                  memahami, dan menyetujui informasi/deskripsi
                                  keseluruhan Barang (termasuk didalamnya namun
                                  tidak terbatas pada warna, kualitas, fungsi,
                                  dan lainnya) sebelum membuat tawaran atau
                                  komitmen untuk membeli.
                                </li>
                                <li class="subJudul3">
                                  Pembeli mengakui bahwa warna sebenarnya dari
                                  produk sebagaimana terlihat di Situs/Aplikasi
                                  UR tergantung pada monitor komputer dan layar
                                  handphone Pembeli. UR telah melakukan upaya
                                  terbaik untuk memastikan warna dalam foto-foto
                                  yang ditampilkan pada Situs/Aplikasi UR muncul
                                  seakurat mungkin, tetapi tidak dapat menjamin
                                  bahwa penampilan warna pada Situs dan aplikasi
                                  UR akan akurat.
                                </li>
                                <li class="subJudul3">
                                  Pengguna masuk ke dalam kontrak yang mengikat
                                  secara hukum untuk membeli Barang ketika
                                  Pengguna membeli suatu barang.
                                </li>
                                <li class="subJudul3">
                                  UR tidak mengalihkan kepemilikan secara hukum
                                  atas barang-barang dari Penjual kepada
                                  Pembeli.
                                </li>
                                <li class="subJudul3">
                                  Ketidaksuaian Produk Ketika di beli Pengguna
                                  bukan tanggung jawab UR. Semua produk yang di
                                  jual melalui UR adalah tanggung jawab dari
                                  Penjual.
                                </li>
                                <li class="subJudul3">
                                  UR tidak bisa mengembalikan Dana dari
                                  transaksi yang sudah terjadi (Artinya Pembeli
                                  dan Penjual sudah sepakat /setuju dalam
                                  transaksi tersebut dan jika terjadi
                                  ketidaksesuaian user dan partner diharapkan
                                  musyawarah).
                                </li>
                              </ul>
                              <li class="subJudul2">
                                Pembeli memahami dan menyetujui bahwa
                                ketersediaan stok Barang merupakan tanggung
                                jawab Penjual yang menawarkan Barang tersebut.
                                Terkait ketersediaan stok Barang dapat berubah
                                sewaktu-waktu, sehingga dalam keadaan stok
                                Barang kosong. maka penjual akan menolak order,
                                dan pembayaran atas barang yang bersangkutan
                                dikembalikan kepada Pembeli.
                              </li>
                              <li class="subJudul2">
                                Pembeli memahami sepenuhnya dan menyetujui bahwa
                                segala transaksi yang dilakukan antara Pembeli
                                dan Penjual selain melalui Member Resmi UR
                                dan/atau tanpa sepengetahuan UR (melalui
                                fasilitas/jaringan pribadi, pengiriman pesan,
                                pengaturan transaksi khusus diluar situs UR atau
                                upaya lainnya) adalah merupakan tanggung jawab
                                pribadi dari Pembeli.
                              </li>
                              <li class="subJudul2">
                                UR memiliki kewenangan sepenuhnya untuk menolak
                                pembayaran tanpa pemberitahuan terlebih dahulu.
                              </li>
                              <li class="subJudul2">
                                Pembeli menyetujui dan memahami bahwa dengan
                                menggunakan Situs/Aplikasi UR pada saat Pembeli
                                melakukan transaksi pembelian, UR akan
                                meneruskan data informasi Pembeli kepada
                                Penjual.
                              </li>
                              <li class="subJudul2">
                                Pembeli wajib melakukan pembayaran dengan metode
                                pembayaran yang dipilin dengan nominal yang
                                sesuai dengan jumlah tagihan beserta kode unik
                                (apabila ada) yang tertera pada halaman
                                pembayaran. PT UR tidak bertanggung jawab atas
                                kerugian yang dialami Pembeli, apabila Pembeli
                                melakukan pembayaran yang tidak sesuai dengan
                                jumlah tagihan yang tertera pada halaman
                                pembayaran.
                              </li>
                              <li class="subJudul2">
                                Pernbayaran oleh Pembeli wajib dilakukan segera
                                (sesuai waktu yang berjalan di app) setelah
                                Pembeli melakukan check-out. Jika dalam batas
                                waktu tersebut pembayaran belum dilakukan oleh
                                Pembeli, UR memiliki kewenangan untuk
                                membatalkan transaksi tersebut. Pengguna tidak
                                berhak mengajukan klaim atau tuntutan atas
                                pembatalan transaksi tersebut.
                              </li>
                              <li class="subJudul2">
                                Pembeli disarankan untuk memeriksa kembali
                                jumlah nominal pembayaran dengan jumlah tagihan
                                yang tertera pada halaman pembayaran.
                              </li>
                              <li class="subJudul2">
                                Pembeli menyetujui untuk tidak memberitahukan
                                atau menyerahkan bukti pembayaran dan/atau data
                                pembayaran kepada pihak lain selain UR. Dalam
                                hal terjadi kerugian akibat pemberitahuan atau
                                penyerahan bukti pembayaran dan/atau data
                                pembayaran oleh Pembeli kepada pihak lain, maka
                                hal tersebut akan menjadi tanggung jawab
                                Pembeli.
                              </li>
                              <li class="subJudul2">
                                Pembeli memahami dan menyetujui bahwa setiap
                                klaim yang dilayangkan setelah adanya konfirmasi
                                / konfirmasi otomatis penerimaan Barang adalah
                                bukan menjadi tanggung jawab UR. Kerugian yang
                                timbul setelah adanya konfirmasi/konfirmasi
                                otomatis penerimaan Barang menjadi tanggung
                                jawab Pembeli secara pribadi.
                              </li>
                              <li class="subJudul2">
                                Pembeli memahami dan menyetujui bahwa setiap
                                masalah pengiriman Barang yang disebabkan
                                keterlambatan pembayaran adalah merupakan
                                tanggung jawab dari Pembeli.
                              </li>
                              <li class="subJudul2">
                                UR berwenang mengambil keputusan atas
                                permasalahan-permasalahan transaksi yang belum
                                terselesaikan akibat tidak adanya kesepakatan
                                penyelesaian, baik antara Penjual dan Pembeli,
                                dengan melihat bukti-bukti yang ada. Keputusan
                                UR adalah keputusan akhir yang tidak dapat
                                diganggu gugat dan mengikat pihak Penjual dan
                                Pembeli untuk mematuhinya.
                              </li>
                              <li class="subJudul2">
                                Harga Barang yang terdapat dalam situs UR adalah
                                harga yang ditetapkan oleh Penjual. Penjual
                                dilarang memanipulasi harga barang dengan cara
                                apapun.
                              </li>
                              <li class="subJudul2">
                                Penjual dilarang menetapkan harga yang tidak
                                wajar pada Barang yang ditawarkan melalui Situs
                                UR. UR berhak untuk melakukan tindakan berupa
                                memindahkan Barang ke gudang. pemeriksaan.
                                penundaan. atau penurunan konten serta tindakan
                                lainnya berdasarkan penilaian sendiri dari UR
                                atas dasar penetapan harga yang tidak wajar.
                              </li>
                              <li class="subJudul2">
                                Pembeli memahami dan menyetujui bahwa kesalahan
                                keterangan harga dan informasi lainnya yang
                                disebabkan tidak terbaharuinya halaman situs UR
                                dikarenakan browser/ISP yang dipakai Pembeli
                                adalah tanggung jawab Pembeli.
                              </li>
                              <li class="subJudul2">
                                Penjual memahami dan menyetujui bahwa kesalahan
                                ketik yang menyebabkan keterangan harga atau
                                informasi lain menjadi tidak benar/sesuai adalah
                                tanggung jawab Penjual. Perlu diingat dalam hal
                                ini, apabila terjadi kesalahan pengetikan
                                keterangan harga Barang yang tidak disengaja,
                                Penjual berhak menolak pesanan Barang yang
                                dilakukan oleh pembeli.
                              </li>
                              <li class="subJudul2">
                                Pengguna memahami dan menyetujui bahwa setiap
                                masalah dan/atau perselisihan yang terjadi
                                akibat ketidaksepahaman antara Penjual dan
                                Pembeli tentang harga bukanlah merupakan
                                tanggung jawab UR.
                              </li>
                              <li class="subJudul2">
                                Dengan melakukan pemesanan melalui UR, Pengguna
                                menyetujui untuk membayar total biaya yang harus
                                dibayarkan sebagaimana tertera dalam halaman
                                pembayaran, yang terdiri dari harga barang.
                                ongkos kirim, dan biaya-biaya lain yang mungkin
                                timbul dan akan diuraikan secara tegas dalam
                                halaman pembayaran. Pengguna setuju untuk
                                melakukan pembayaran melalui metode pembayaran
                                yang telah dipilih sebelumnya oleh Pengguna.
                              </li>
                              <li class="subJudul2">
                                Batasan harga maksimal satuan untuk Barang yang
                                dapat ditawarkan adalah Rp. 100.000.000,-
                              </li>
                              <li class="subJudul2">
                                Situs UR untuk saat ini hanya melayani transaksi
                                jual beli Barang dalam mata uang Rupiah.
                              </li>
                            </ul>
                          </li>
                          <li class="isiSubJudul">
                            <h5>KONTEN, INFORMASI DAN PROMOSI</h5>
                            <ul>
                              <li class="subJudul2">KONTEN</li>
                              <ul>
                                <li class="subJudul3">
                                  Dalam menggunakan setiap fitur dan/atau
                                  layanan UR, Pengguna dilarang untuk
                                  menggunggah atau mempergunakan kata-kata,
                                  komentar, gambar, video atau konten apapun
                                  yang mengandung unsur SARA, diskriminasi,
                                  merendahkan atau menyudutkan orang lain,
                                  vulgar, bersifat ancaman, beriklan atau
                                  melakukan promosi ke situs selain Situs UR,
                                  atau hal-hal lain yang dapat dianggap tidak
                                  sesuai dengan nilai dan norma sosial maupun
                                  berdasarkan kebijakan yang ditentukan sendiri
                                  oleh UR. UR berhak melakukan tindakan yang
                                  diperlukan atas pelanggaran ketentuan ini,
                                  antara lain penghapusan konten, moderasi toko,
                                  pemblokiran akun, dan lain-lain. Ketentuan
                                  lebih lanjut mengenai kebijakan konten UR
                                  dapat dilihat <a href="">DISINI</a> dan
                                  ketentuan video dapat dilihat{" "}
                                  <a href="">DISINI</a>.
                                </li>
                                <li class="subJudul3">
                                  Pengguna dilarang mempergunakan foto/gambar
                                  Barang yang memiliki watermark yang menandakan
                                  hak kepemilikan orang lain.
                                </li>
                                <li class="subJudul3">
                                  Pengguna dengan ini memahami dan menyetujui
                                  bahwa penyalahgunaan foto/gambar yang di
                                  unggah oleh Pengguna adalah tanggung jawab
                                  Pengguna secara pribadi.
                                </li>
                                <li class="subJudul3">
                                  Penjual tidak diperkenankan untuk
                                  mempergunakan foto/gambar Barang atau logo
                                  toko sebagai media untuk beriklan atau
                                  melakukan promosi ke situs-situs lain diluar
                                  Situs UR, atau memberikan data kontak pribadi
                                  untuk melakukan transaksi secara langsung
                                  kepada pembeli / calon pembeli.
                                </li>
                                <li class="subJudul3">
                                  Penjual menjamin foto/gambar yang diunggah
                                  tidak termasuk konten yang dilarang
                                  berdasarkan Poin I. 1 atau melanggar hak milik
                                  pihak lain. Apabila didapati foto/gambar
                                  melanggar konten berdasarkan Poin I. 1 atau
                                  terdapat laporan pelanggaran hak milik pihak
                                  lain, maka UR berhak melakukan tindakan yang
                                  diperlukan atas pelanggaran ketentuan ini,
                                  termasuk namun tidak terbatas pada penghapusan
                                  konten dan/atau tindakan lainnya yang perlu
                                  dilakukan.
                                </li>
                                <li class="subJudul3">
                                  Ketika Pengguna mengunggah ke Situs UR dengan
                                  konten atau posting konten, Pengguna
                                  memberikan UR hak non-eksklusif, di seluruh
                                  dunia, secara terus-menerus, tidak dapat
                                  dibatalkan, bebas royalti, disublisensikan
                                  (melalui beberapa tingkatan) hak untuk
                                  melaksanakan setiap dan semua hak cipta,
                                  publisitas, merek dagang. hak basis data dan
                                  hak kekayaan intelektual yang Pengguna miliki
                                  dalam konten, di media manapun yang dikenal
                                  sekarang atau di masa depan. Selanjutnya,
                                  untuk sepenuhnya diizinkan oleh hukum yang
                                  berlaku, Anda mengesampingkan hak moral dan
                                  berjanji untuk tidak menuntut hak-hak tersebut
                                  terhadap UR.
                                </li>
                                <li class="subJudul3">
                                  Pengguna menjamin bahwa tidak melanggar hak
                                  kekayaan intelektual dalam mengunggah konten
                                  Pengguna kedalam situs UR. Setiap Pengguna
                                  dengan ini bertanggung jawab secara pribadi
                                  atas pelanggaran hak kekayaan intelektual
                                  dalam mengunggah konten di Situs UR.
                                </li>
                                <li class="subJudul3">
                                  UR menyediakan fitur "Diskusi Produk" untuk
                                  memudahkan pembeli berinteraksi dengan
                                  penjual, perihal Barang yang ditawarkan.
                                  Penjual tidak diperkenankan menggunakan fitur
                                  tersebut untuk tujuan dengan cara apa pun
                                  menaikkan harga Barang dagangannya. termasuk
                                  di dalamnya memberi komentar pertama kali atau
                                  memberi komentar selanjutnya / terus menerus
                                  secara berkala (flooding / spam).
                                </li>
                                <li class="subJudul3">
                                  Meskipun kami mencoba untuk menawarkan
                                  informasi yang dapat diandalkan, kami tidak
                                  bisa menjanjikan bahwa katalog akan selalu
                                  akurat dan terbarui, dan Pengguna setuju bahwa
                                  Pengguna tidak akan meminta UR bertanggung
                                  jawab atas ketimpangan dalam katalog. Katalog
                                  mungkin termasuk hak cipta, merek dagang atau
                                  hak milik lainnya.
                                </li>
                                <li class="subJudul3">
                                  Konten atau materi yang akan ditampilkan atau
                                  ditayangkan pada Situs/Aplikasi UR melalui
                                  Feed akan tunduk pada Ketentuan Situs,
                                  peraturan hukum, serta etika pariwara yang
                                  berlaku.
                                </li>
                                <li class="subJudul3">
                                  Pengguna atau pihak lainnya yang menggunakan
                                  fitur Feed bertanggungjawab penuh terhadap
                                  konten atau materi yang diunggah melalui fitur
                                  Feed.
                                </li>
                                <li class="subJudul3">
                                  UR berhak untuk sewaktu-waktu menurunkan
                                  konten atau materi yang terdapat pada Feed
                                  yang dianggap melanggar Syarat dan Ketentuan
                                  Situs, peraturan hukum yang berlaku, serta
                                  etika pariwara yang berlaku.
                                </li>
                                <li class="subJudul3">
                                  Penjualan produk yang mengandung tembakau
                                  dan/atau rokok elektrik hanya diperkenankan di
                                  jual kedalam 6 (enam) kategori tertentu,
                                  yaitu:
                                </li>
                                <ul>
                                  <li class="subJudul4">
                                    Kategori Produk Dewasa Lainnya:
                                  </li>
                                  <li class="subJudul4">
                                    Kategori E-cigarettes;
                                  </li>
                                  <li class="subJudul4">
                                    Kategori Liguid Vape;
                                  </li>
                                  <li class="subJudul4">
                                    Kategori Paket Vaporizer;
                                  </li>
                                  <li class="subJudul4">
                                    Kategori MOD; dan/atau
                                  </li>
                                  <li class="subJudul4">Kategori Atomizer.</li>
                                </ul>
                                <li class="subJudul3">
                                  UR berhak untuk sewaktu-waktu tanpa
                                  pemberitahuan sebelumnya, untuk melakukan
                                  tindakan penurunan konten yang menjual produk
                                  rokok elektrik, perangkat rokok elektrik,
                                  cairan rokok elektrik, dan/atau produk yang
                                  mengandung tembakau di platform Android.
                                  termasuk namun tidak terbatas pada produk
                                  rokok tembakau, nikotin, liguid vape. mod.
                                  pod, heatstick, vaporizer dan/atau
                                  E-Cigarette, mengacu pada kebijakan Google
                                  Play Store yang dapat dilihat{" "}
                                  <a href="">
                                    <a href="">DISINI</a>
                                  </a>
                                </li>
                                <li class="subJudul3">
                                  UR berhak sewaktu-waktu tanpa pemberitahuan
                                  sebelumnya, untuk mengambil tindakan berupa
                                  penurunan reputasi toko atau moderasi toko,
                                  apabila Penjual diduga melakukan manipulasi
                                  konten sebagaimana diatur dalam poin I.14,
                                  termasuk namun tidak terbatas pada manipulasi
                                  penulisan nama produk. gambar produk,
                                  deskripsi produk dan/atau kegiatan lainnya
                                  yang bertujuan untuk mengelabuhi penjualan
                                  konten sebagaimana diatur dalam poin I.14 agar
                                  tetap dapat melakukan penawaran menggunakan
                                  Aplikasi Android.
                                </li>
                                <li class="subJudul3">
                                  Pengguna harus mengikuti guideline untuk
                                  konten yang telah di siapkan pihak UR Ketika
                                  ingin meng-upload ke system ur.
                                </li>
                                <ul>
                                  <li class="subJudul4">
                                    Gambar harus jelas dan terang
                                  </li>
                                  <li class="subJudul4">Foto harus Orisinil</li>
                                  <li class="subJudul4">
                                    Size Upload harus di bawah 1MB
                                  </li>
                                  <li class="subJudul4">
                                    Gambar harus mengikuti Format Ukuran, Untuk
                                    menu kotak 1:1
                                  </li>
                                  <li class="subJudul4">
                                    Gambar harus relevant (Makanan harus foto
                                    makanan tidak boleh foto orang)
                                  </li>
                                </ul>
                                <li class="subJudul3">
                                  Pihak UR Berhak untuk menolak Konten apabila
                                  tidak sesuai dengan peraturan yang ada.
                                </li>
                              </ul>
                              <li class="subJudul2">PROMO</li>
                              <ul>
                                <li class="subJudul3">
                                  UR sewaktu-waktu dapat mengadakan kegiatan
                                  promosi (selanjutnya disebut sebagai “Promo”)
                                  dengan Syarat dan Ketentuan yang mungkin
                                  berbeda pada masing-masing kegiatan Promo.
                                  Pengguna dihimbau untuk membaca dengan seksama
                                  Syarat dan Ketentuan Promo tersebut.
                                </li>
                                <li class="subJudul3">
                                  Pengguna hanya boleh menggunakan 1 (satu) akun
                                  UR untuk mengikuti setiap promo UR. Jika
                                  ditemukan pembuatan lebih dari 1 (satu) akun
                                  oleh 1 (satu) Pengguna yang mempunyai
                                  informasi akun yang sama dan/atau identitas
                                  pembayaran yang sama, maka Pengguna tidak
                                  berhak mendapatkan manfaat dari promo UR.
                                </li>
                                <li class="subJudul3">
                                  Pada transaksi tertentu Pengguna akan
                                  berkesempatan untuk mendapatkan Promo Cashback
                                  dalam bentuk UR points . Khusus untuk UR
                                  points, Pengguna diwajibkan untuk mengikuti
                                  ketentuan UR points yang dapat dilihat{" "}
                                  <a href="">DISINI</a>.
                                </li>
                                <li class="subJudul3">
                                  UR points dapat diperoleh dan dikumpulkan oleh
                                  Pembeli dengan melakukan transaksi pembelian
                                  Produk Fisik pada Situs/Aplikasi menggunakan
                                  kode promo, Kupon, dan/atau kupon penjual
                                  tertentu. UR points dapat digunakan dan
                                  ditukarkan sebagai unsur pengurang harga atas
                                  transaksi pembelian Produk Fisik dan/atau
                                  Produk Digital pada Situs/Aplikasi namun UR
                                  points bukan merupakan metode pembayaran
                                  melainkan program loyalitas yang terdapat di
                                  Situs/Aplikasi UR.
                                </li>
                                <li class="subJudul3">
                                  Pastikan nomor handphone yang sudah
                                  terverifikasi aktif pada saat melakukan
                                  transaksi. Jika terjadi perubahan nomor
                                  handphone pada akun UR pengguna karena nomor
                                  sebelumnya sudah tidak aktif, maka pengguna
                                  tidak akan mendapatkan cashback.
                                </li>
                                <li class="subJudul3">
                                  Apabila terdapat transaksi Promo yang
                                  mempergunakan metode pembayaran kartu kredit
                                  yang melanggar Syarat dan Ketentuan UR, maka
                                  akan merujuk pada Syarat dan Ketentuan Poin L.
                                  Kartu Kredit.
                                </li>
                                <li class="subJudul3">
                                  UR berhak, tanpa pemberitahuan sebelumnya,
                                  melakukan tindakan-tindakan yang diperlukan
                                  termasuk namun tidak terbatas pada menarik
                                  subsidi atau cashback, membatalkan benefit
                                  Rewards, pencabutan Promo, membatalkan
                                  transaksi, menahan dana, menurunkan reputasi
                                  toko. menutup toko atau akun, serta hal-hal
                                  lainnya jika ditemukan adanya manipulasi,
                                  penggunaan resi yang tidak valid pada
                                  mayoritas transaksi, manipulasi berat barang
                                  pada deskripsi produk (aktual berat barang dan
                                  berat barang pada deskripsi Produk harus
                                  sesuai), pelanggaran maupun pemanfaatan Promo
                                  untuk keuntungan pribadi Pengguna, maupun
                                  indikasi kecurangan atau pelanggaran
                                  pelanggaran Syarat dan Ketentuan UR dan
                                  ketentuan hukum yang berlaku di wilayah negara
                                  Indonesia.
                                </li>
                                <li class="subJudul3">
                                  Setiap Promo UR tidak berlaku untuk pembelian
                                  logam mulia batangan, voucher belanja, voucher
                                  internet, voucher pulsa, paket data, dan
                                  minuman beralkohol.
                                </li>
                              </ul>
                            </ul>
                          </li>
                          <li class="isiSubJudul">
                            <h5>BATASAN TANGGUNG JAWAB</h5>
                            <ul>
                              <li class="subJudul2">
                                UR adalah portal dengan model Customer to
                                Customer Marketplace, yang menyediakan layanan
                                kepada Pengguna untuk dapat menjadi Penjual
                                maupun Pembeli di website UR. Dengan demikian
                                transaksi yang terjadi adalah transaksi antar
                                member UR, sehingga Pengguna memahami bahwa
                                batasan tanggung jawab UR secara proporsional
                                adalah sebagai penyedia jasa portal web.
                              </li>
                              <li class="subJudul2">
                                UR selalu berupaya untuk menjaga Layanan UR
                                aman, nyaman, dan berfungsi dengan baik, tapi
                                kami tidak dapat menjamin Operasi terus-menerus
                                atau akses ke Layanan kami dapat selalu
                                sempurna. Informasi dan data dalam situs UR
                                memiliki kemungkinan tidak terjadi secara real
                                time.
                              </li>
                              <li class="subJudul2">
                                Pengguna setuju bahwa Anda memanfaatkan Layanan
                                UR atas risiko Pengguna sendiri, dan Layanan UR
                                diberikan kepada Anda pada "SEBAGAIMANA ADANYA"
                                dan "SEBAGAIMANA TERSEDIA".
                              </li>
                              <li class="subJudul2">
                                Sejauh diizinkan oleh hukum yang berlaku, UR
                                (termasuk Induk Perusahaan. direktur. dan
                                karyawan) adalah tidak bertanggung jawab, dan
                                Anda setuju untuk tidak menuntut UR bertanggung
                                jawab, atas segala kerusakan atau kerugian
                                (termasuk namun tidak terbatas pada hilangnya
                                uang, reputasi, keuntungan, atau kerugian tak
                                berwujud lainnya) yang diakibatkan secara
                                langsung atau tidak langsung dari:
                              </li>
                              <ul>
                                <li class="subJudul3">
                                  Penggunaan atau ketidakmampuan Pengguna dalam
                                  menggunakan Layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Harga, Pengiriman atau petunjuk lain yang
                                  tersedia dalam layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Keterlambatan atau gangguan dalam Layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Kelalaian dan kerugian yang ditimbulkan oleh
                                  masing-masing Pengguna.
                                </li>
                                <li class="subJudul3">Kualitas Barang.</li>
                                <li class="subJudul3">Pengiriman Barang.</li>
                                <li class="subJudul3">
                                  Pelanggaran Hak atas Kekayaan Intelektual.
                                </li>
                                <li class="subJudul3">
                                  Perselisihan antar pengguna.
                                </li>
                                <li class="subJudul3">
                                  Pencemaran nama baik pihak lain.
                                </li>
                                <li class="subJudul3">
                                  Setiap penyalahgunaan Barang yang sudah dibeli
                                  pihak Pengguna.
                                </li>
                                <li class="subJudul3">
                                  Kerugian akibat pembayaran tidak resmi kepada
                                  pihak lain selain ke Member Resmi UR, yang
                                  dengan cara apapun mengatas-namakan UR ataupun
                                  kelalaian penulisan Member dan/atau informasi
                                  lainnya dan/atau kelalaian pihak bank.
                                </li>
                                <li class="subJudul3">
                                  Pengiriman untuk perbaikan Barang yang
                                  bergaransi resmi dari pihak produsen. Pembeli
                                  dapat membawa Barang langsung kepada pusat
                                  layanan servis terdekat dengan kartu garansi
                                  dan faktur pembelian.
                                </li>
                                <li class="subJudul3">
                                  Virus atau perangkat lunak berbahaya lainnya
                                  (bot, script. automation tool selain fitur
                                  Power Merchant, Power Merchant Pro, hacking
                                  tool) yang diperoleh dengan mengakses, atau
                                  menghubungkan ke layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Gangguan. bug. kesalahan atau ketidakakuratan
                                  apapun dalam Layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Kerusakan pada perangkat keras Anda dari
                                  penggunaan setiap Layanan UR.
                                </li>
                                <li class="subJudul3">
                                  Isi, tindakan, atau tidak adanya tindakan dari
                                  pihak ketiga. termasuk terkait dengan Produk
                                  yang ada dalam situs UR yang diduga palsu.
                                </li>
                                <li class="subJudul3">
                                  Tindak penegakan yang diambil sehubungan
                                  dengan akun Pengguna.
                                </li>
                                <li class="subJudul3">
                                  Adanya tindakan peretasan yang dilakukan oleh
                                  pihak ketiga kepada akun pengguna.
                                </li>
                              </ul>
                              <li class="subJudul2">
                                Kami menyediakan Aplikasi sebagaimana adanya dan
                                Kami tidak menyatakan atau menjamin bahwa
                                keandalan, ketepatan waktu, kualitas,
                                kesesuaian, ketersediaan, akurasi, kelengkapan
                                atau keamanan dari Aplikasi dapat memenuhi
                                kebutuhan dan akan sesuai dengan harapan Anda,
                                termasuk namun tidak terbatas pada Layanan,
                                Konten Pihak Ketiga, Penawaran dan Metode
                                Pembayaran yang sepenuhnya menjadi tanggung
                                jawab Penyedia Layanan, Penyedia Konten Pihak
                                Ketiga, Penyedia Penawaran dan Penyedia Metode
                                Pembayaran. Kami tidak bertanggung jawab atas
                                setiap kerugian atau kerusakan yang disebabkan
                                oleh setiap kegagalan atau kesalahan yang
                                dilakukan oleh Penyedia Layanan, Penyedia Konten
                                Pihak Ketiga, Penyedia Penawaran atau Penyedia
                                Metode Pembayaran ataupun kegagalan atau
                                kesalahan Anda dalam mematuhi Ketentuan
                                Penggunaan Kami, Penyedia Layanan, Penyedia
                                Konten Pihak Ketiga, Penyedia Penawaran atau
                                Penyedia Metode Pembayaran.
                              </li>
                              <li class="subJudul2">
                                Aplikasi dapat mengalami keterbatasan,
                                penundaan, dan masalah-masalah lain yang
                                terdapat dalam penggunaan internet dan
                                komunikasi elektronik, termasuk perangkat Anda,
                                Penyedia Layanan, Penyedia Konten Pihak Ketiga,
                                Penyedia Penawaran atau Penyedia Metode
                                Pembayaran rusak, tidak terhubung dengan
                                internet, berada di luar jangkauan, dimatikan
                                atau tidak berfungsi. Kami tidak bertanggung
                                jawab atas keterlambatan, kegagalan pengiriman,
                                kerusakan atau kerugian yang diakibatkan oleh
                                masalah-masalah tersebut.
                              </li>
                              <li class="subJudul2">
                                Kami tidak berkewajiban untuk mengawasi akses
                                atau penggunaan Anda atas Aplikasi. Namun, Kami
                                tetap melakukan pengawasan untuk tujuan
                                memastikan kelancaran penggunaan Aplikasi dan
                                untuk memastikan kepatuhan terhadap Ketentuan
                                Penggunaan ini, peraturan perundang-undangan
                                yang berlaku, putusan pengadilan, dan/atau
                                ketentuan lembaga administratif atau badan
                                pemerintahan lainnya.
                              </li>
                              <li class="subJudul2">
                                Kami tidak mempunyai kewajiban apapun, termasuk
                                untuk mengambil tindakan lebih jauh atau
                                tindakan hukum yang dianggap perlu oleh Anda,
                                Penyedia Layanan, Penyedia Konten Pihak Ketiga,
                                Penyedia Penawaran atau Penyedia Metode
                                Pembayaran, terhadap setiap permasalahan atau
                                perselisihan yang timbul antara Anda dan
                                Penyedia Layanan, Penyedia Konten Pihak Ketiga,
                                Penyedia Penawaran atau Penyedia Metode
                                Pembayaran. Tetapi, Kami akan memfasilitasi
                                setiap permasalahan atau perselisihan yang
                                timbul antara Anda dan Penyedia Layanan,
                                Penyedia Konten Pihak Ketiga, Penyedia Penawaran
                                atau Penyedia Metode Pembayaran dengan upaya
                                wajar yang diperlukan. Ketika Kami memfasilitasi
                                penyelesaian permasalahan atau perselisihan
                                antara Anda dan Penyedia Layanan, Penyedia
                                Konten Pihak Ketiga, Penyedia Penawaran atau
                                Penyedia Metode Pembayaran, Kami tidak bertindak
                                sebagai mediator dan hal tersebut tidak
                                menimbulkan kewajiban lebih jauh apapun terhadap
                                Kami.
                              </li>
                              <li class="subJudul2">Tanggung Jawab Pengguna</li>
                              <ul>
                                <li class="subJudul3">
                                  Anda bertanggung jawab penuh atas keputusan
                                  yang Anda buat untuk menggunakan atau
                                  mengakses Aplikasi, Layanan, Konten Pihak
                                  Ketiga, Penawaran atau Metode Pembayaran. Anda
                                  harus memperlakukan Penyedia Layanan, Penyedia
                                  Konten Pihak Ketiga, Penyedia Penawaran dan
                                  Penyedia Metode Pembayaran dengan hormat dan
                                  tidak boleh terlibat dalam perilaku atau
                                  tindakan yang tidak sah, mengancam atau
                                  melecehkan ketika menggunakan Layanan, Konten
                                  Pihak Ketiga, Penawaran atau Metode
                                  Pembayaran.
                                </li>
                                <li class="subJudul3">
                                  Anda bertanggung jawab secara penuh atas
                                  setiap kerugian dan/atau klaim yang timbul
                                  dari penggunaan Aplikasi, Layanan, Konten
                                  Pihak Ketiga, Penawaran atau Metode Pembayaran
                                  melalui Akun Anda, baik oleh Anda atau pihak
                                  lain yang menggunakan Akun Anda, dengan cara
                                  yang bertentangan dengan Ketentuan Penggunaan
                                  ini, Kebijakan Privasi, termasuk syarat dan
                                  ketentuan dan kebijakan privasi yang
                                  ditentukan oleh Penyedia Layanan, Penyedia
                                  Konten Pihak Ketiga, Penyedia Penawaran dan
                                  Penyedia Metode Pembayaran, atau peraturan
                                  perundang-undangan yang berlaku, termasuk
                                  namun tidak terbatas untuk tujuan anti
                                  pencucian uang, anti pendanaan terorisme,
                                  aktivitas kriminal, penipuan dalam bentuk
                                  apapun (termasuk namun tidak terbatas pada
                                  kegiatan phishing dan/atau social
                                  engineering), pelanggaran hak kekayaan
                                  intelektual, dan/atau aktivitas lain yang
                                  merugikan publik dan/atau pihak lain manapun
                                  atau yang dapat atau dianggap dapat merusak
                                  reputasi Kami.
                                </li>
                                <li class="subJudul3">
                                  PIN, Password, dan Kode Transaksi hanya boleh
                                  digunakan oleh User yang bersangkutan.
                                </li>
                                <li class="subJudul3">
                                  User wajib merahasiakan PIN, Password,
                                  dan/atau Kode Transaksi dengan cara:
                                </li>
                                <li class="subJudul3">
                                  Tidak memberitahukan PIN, Password, dan/atau
                                  Kode Transaksi kepada orang lain termasuk
                                  kepada anggota keluarga atau orang terdekat
                                  User;
                                </li>
                                <li class="subJudul3">
                                  Tidak menyimpan PIN dan Password pada
                                  handphone, benda-benda lainnya atau sarana apa
                                  pun yang memungkinkan PIN dan Password
                                  diketahui oleh orang lain;
                                </li>
                                <li class="subJudul3">
                                  Berhati-hati dalam menggunakan PIN, Password,
                                  dan/atau Kode Transaksi agar tidak terlihat
                                  oleh orang lain;
                                </li>
                                <li class="subJudul3">
                                  Tidak menggunakan nomor handphone, Password
                                  dan/atau PIN yang ditentukan atau dipilihkan
                                  oleh orang lain atau yang mudah diterka
                                  seperti tanggal lahir atau kombinasinya, dan
                                  nomor telepon.
                                </li>
                                <li class="subJudul3">
                                  Segala akibat yang timbul dari penyalahgunaan
                                  PIN, Password, dan/atau Kode Transaksi,
                                  termasuk namun tidak terbatas pada
                                  penyalahgunaan yang mengakibatkan PIN,
                                  Password, dan/atau Kode Transaksi menjadi
                                  diketahui dan/atau digunakan oleh orang/pihak
                                  lain merupakan tanggung jawab User sepenuhnya.
                                  User dengan ini membebaskan{" "}
                                  <a class="namaPT"></a> dari segala
                                  klaim/gugatan/tuntutan yang timbul, baik dari
                                  pihak tain maupun User sendiri sebagai akibat
                                  penyalahgunaan PIN, Password, dan/atau Kode
                                  Transaksi.
                                </li>
                                <li class="subJudul3">
                                  Penggunaan PIN, Password, dan/atau Kode
                                  Transaksi pada UR mempunyai kekuatan hukum
                                  yang sama dengan perintah tertulis yang
                                  ditandatangani oleh User.
                                </li>
                                <li class="subJudul3">
                                  User setiap saat dapat melakukan perubahan PIN
                                  atau Password pada UR dan disarankan untuk
                                  melakukan perubahan secara berkala.
                                </li>
                                <li class="subJudul3">
                                  Apabila SIM Card Operator Seluler atau
                                  handphone milik User
                                  rusak/hilang/dicuri/dipindahtangankan kepada
                                  pihak lain, User harus segera memberitahukan
                                  hal tersebut kepada <a class="namaPT"></a>.
                                  Segala instruksi transaksi berdasarkan
                                  penggunaan nomor handphone, PIN, dan/atau Kode
                                  Transaksi yang terjadi sebelum{" "}
                                  <a class="namaPT"></a> menerima pemberitahuan
                                  tersebut dari User menjadi tanggung jawab User
                                  sepenuhnya.
                                </li>
                                <li class="subJudul3">
                                  User wajib memastikan bahwa handphone yang
                                  digunakan untuk bertransaksi bebas dari virus,
                                  malware, dan/atau hal lainnya yang dapat
                                  merugikan User dan dengan ini User membebaskan{" "}
                                  <a class="namaPT"></a> dari segala
                                  klaim/gugatan/tuntutan dari pihak manapun
                                  termasuk dari User sendiri sehubungan dengan
                                  adanya gangguan eksternal akibat virus,
                                  hacking, malware, phishing, dan/atau hal
                                  lainnya termasuk namun tidak terbatas pada
                                  kejahatan dunia maya lainnya.
                                </li>
                                <li class="subJudul3">
                                  Pengguna akan melepaskan UR dari tuntutan
                                  ganti rugi dan menjaga UR (termasuk Induk
                                  Perusahaan, direktur, dan karyawan) dari
                                  setiap klaim atau tuntutan, termasuk biaya
                                  hukum yang wajar. yang dilakukan oleh pihak
                                  ketiga yang timbul dalam hal Anda melanggar
                                  Perjanjian ini, penggunaan Layanan UR yang
                                  tidak semestinya dan/ atau pelanggaran Anda
                                  terhadap hukum atau hak-hak pihak ketiga.
                                </li>
                              </ul>
                            </ul>
                          </li>
                          <li class="isiSubJudul">BIAYA</li>
                          <ul>
                            <li class="subJudul2">
                              Pembeli memahami dan mengerti bahwa UR telah
                              melakukan usaha sebaik mungkin dalam memberikan
                              informasi tarif pengiriman kepada Pembeli
                              berdasarkan lokasi secara akurat, namun UR tidak
                              dapat menjamin keakuratan data tersebut dengan
                              yang ada pada cabang setempat.
                            </li>
                            <li class="subJudul2">
                              Karena itu UR menyarankan kepada Penjual untuk
                              mencatat terlebih dahulu tarif yang diberikan UR,
                              agar dapat dibandingkan dengan tarif yang
                              dibebankan di cabang setempat. Apabila mendapati
                              perbedaan. mohon sekiranya untuk menginformasikan
                              kepada kami melalui menu contact us dengan
                              memberikan data harga yang didapat beserta kota
                              asal dan tujuan, agar dapat kami telusuri lebih
                              lanjut.
                            </li>
                            <li class="subJudul2">
                              Pengguna memahami dan menyetujui bahwa selisih
                              biaya pengiriman Barang adalah di luar tanggung
                              jawab UR, dan oleh karenaitu, adalah kebijakan
                              Penjual sendiri untuk membatalkan atau tetap
                              melakukan pengiriman Barang.
                            </li>
                            <li class="subJudul2">
                              Biaya Layanan bisa di lihat di (
                              <a class="webUR" href="https://ur-hub.com/">
                                www.ur-hub.com
                              </a>
                              )
                            </li>
                            <li class="subJudul2">
                              Biaya Layanan adalah biaya yang dikenakan kepada
                              Pengguna untuk tujuan pemeliharaan sistem dan
                              peningkatan layanan dalam bertransaksi melalui
                              Situs UR dan ketentuan lebih lanjut mengenai biaya
                              layanan dapat dilihat DISINI.
                            </li>
                            <li class="subJudul2">
                              Sebelum menggunakan Aplikasi, Anda harus
                              menyetujui Ketentuan Penggunaan ini dan Kebijakan
                              Privasi, dan mendaftarkan diri Anda dengan
                              memberikan informasi yang dibutuhkan oleh Kami.
                              Saat melakukan pendaftaran, Kami akan meminta Anda
                              untuk memberikan nama lengkap, alamat email, dan
                              nomor telepon seluler pribadi Anda yang sah. Anda
                              dapat mengubah informasi data diri Anda pada fitur
                              pengaturan dalam Aplikasi.
                            </li>
                            <li class="subJudul2">
                              Setelah melakukan pendaftaran, sistem Kami akan
                              menghasilkan kode verifikasi secara otomatis dan
                              mengirim kode verifikasi tersebut melalui pesan
                              singkat ke nomor telepon genggam yang Anda
                              berikan. Anda perlu melakukan verifikasi dengan
                              memasukan kode verifikasi tersebut pada halaman
                              pendaftaran di Aplikasi.
                            </li>
                            <li class="subJudul2">
                              Setelah melakukan verifikasi, sistem Kami akan
                              membuatkan akun UR pribadi (“Akun”) untuk Anda
                              yang dapat digunakan untuk menggunakan Aplikasi
                              dan memesan Layanan melalui Aplikasi. Nomor
                              telepon genggam Anda melekat pada Akun Anda
                              sehingga Anda tidak bisa membuat Akun baru dengan
                              nomor telepon genggam yang sudah didaftarkan. Hal
                              yang sama juga berlaku apabila di kemudian hari
                              Anda mengubah nomor telepon genggam Anda pada menu
                              pengaturan di Aplikasi.
                            </li>
                            <li class="subJudul2">
                              Dalam hal Anda telah keluar dari Akun Anda, maka
                              Anda perlu memasukan alamat surat elektronik atau
                              nomor telepon genggam yang ada berikan pada saat
                              mendaftarkan diri Anda dan memasukan kode
                              verifikasi, yang kemudian dikirim secara otomatis
                              oleh sistem Kami ke nomor telepon genggam
                              terdaftar Anda, pada halaman pengaksesan.
                            </li>
                            <li class="subJudul2">
                              KODE VERIFIKASI (ONE TIME PASSWORD/OTP) DIHASILKAN
                              SECARA OTOMATIS OLEH SISTEM KAMI. KAMI TIDAK
                              MENGETAHUI DAN TIDAK PERNAH MEMINTA KODE
                              VERIFIKASI ANDA. JANGAN PERNAH MEMBERITAHUKAN KODE
                              VERIFIKASI ANDA KEPADA SIAPAPUN BAHKAN KEPADA KAMI
                              ATAU PIHAK LAIN YANG MENGAKU SEBAGAI PERWAKILAN
                              KAMI.
                            </li>
                            <li class="subJudul2">
                              Jika Anda menggunakan perangkat yang mendukung
                              fitur sidik jari (fingerprint) dan/atau pengenalan
                              wajah (facial recognition), Anda dapat
                              mengaktifkan fitur otentikasi perangkat tersebut
                              untuk masuk ke Aplikasi kami.
                            </li>
                            <li class="subJudul2">
                              Ketika Anda mengaktifkan fitur sidik jari dan /
                              atau pengenalan wajah di perangkat seluler Anda
                              untuk keperluan otentikasi di Aplikasi, harap
                              dicatat bahwa Kami tidak menyimpan data biometrik
                              tersebut. Kecuali jika diberitahukan sebaliknya
                              kepada Anda, data tersebut disimpan di perangkat
                              seluler Anda dan juga dapat disimpan oleh pihak
                              ketiga, seperti pabrik pembuat perangkat Anda.
                              Anda setuju dan mengakui bahwa kami tidak
                              bertanggung jawab atas akses atau kehilangan yang
                              tidak sah terhadap data biometrik yang disimpan di
                              perangkat Anda.
                            </li>
                            <li class="subJudul2">
                              Jika Anda mengganti perangkat seluler Anda, OTP
                              masih akan diperlukan bagi Anda untuk masuk ke
                              Aplikasi. Jika Anda ingin mengaktifkan fitur
                              otentikasi perangkat di perangkat baru Anda, Anda
                              dapat melakukannya di pengaturan perangkat seluler
                              Anda.
                            </li>
                            <li class="subJudul2">
                              Akun Anda hanya dapat digunakan oleh Anda dan
                              tidak bisa dialihkan kepada orang lain dengan
                              alasan apapun. Kami berhak menolak untuk
                              memfasilitasi pesanan jika Kami mengetahui atau
                              mempunyai alasan yang cukup untuk menduga bahwa
                              Anda telah mengalihkan atau membiarkan Akun Anda
                              digunakan oleh orang lain.
                            </li>
                            <li class="subJudul2">
                              Keamanan dan kerahasiaan Akun Anda, termasuk nama
                              terdaftar, alamat surat elektronik terdaftar,
                              nomor telepon genggam terdaftar, rincian
                              pembayaran dan Metode Pembayaran yang Anda pilih,
                              serta kode verifikasi yang dihasilkan dan dikirim
                              oleh sistem Kami atau Penyedia Metode Pembayaran
                              sepenuhnya merupakan tanggung jawab pribadi Anda.
                              Semua kerugian dan risiko yang ada akibat
                              kelalaian Anda menjaga keamanan dan kerahasiaan
                              sebagaimana disebutkan ditanggung oleh Anda
                              sendiri. Dalam hal demikian, Kami akan menganggap
                              setiap penggunaan atau pesanan yang dilakukan
                              melalui Akun Anda sebagai permintaan yang sah dari
                              Anda.
                            </li>
                            <li class="subJudul2">
                              Segera beritahukan Kami jika Anda mengetahui atau
                              menduga bahwa Akun Anda telah digunakan tanpa
                              sepengetahuan dan persetujuan Anda. Kami akan
                              melakukan Tindakan yang Kami anggap perlu dan
                              dapat Kami lakukan terhadap penggunaan tanpa
                              persetujuan tersebut.
                            </li>
                            <li class="subJudul2">
                              Jika Anda seorang Penyedia Layanan, Anda tidak
                              dapat menggunakan Akun pribadi Anda atau Akun
                              orang lain untuk memesan Layanan yang akan Anda
                              terima sendiri sebagai seorang Penyedia Layanan.
                            </li>
                            <li class="subJudul2">
                              Pengunduhan perangkat lunak dan penggunaan
                              Aplikasi adalah bebas biaya. Namun, Kami dapat
                              mengenakan biaya untuk penggunaan fitur tertentu
                              pada Aplikasi di kemudian hari.
                            </li>
                            <li class="subJudul2">
                              Layanan yang tersedia pada Aplikasi dikenakan
                              biaya yang dapat Anda temukan pada Aplikasi
                              sebelum Anda memesan Layanan tersebut. Kami dapat
                              mengubah atau memperbaharui biaya dari waktu ke
                              waktu berdasarkan faktor tertentu, antara lain
                              lokasi, waktu, jenis Layanan dan peraturan
                              perundang-undangan yang berlaku. Kami juga dapat
                              membebankan harga dan/atau biaya dengan besaran
                              tertentu sebagai pembayaran untuk penggunaan
                              Aplikasi yang dapat dipungut oleh Kami, afiliasi
                              Kami, atau Penyedia Layanan.
                            </li>
                            <li class="subJudul2">
                              Anda dapat melakukan pembayaran terhadap Layanan,
                              Konten Pihak Ketiga atau Penawaran yang Anda akses
                              atau gunakan dengan uang tunai atau melalui metode
                              pembayaran elektronik, yang disediakan oleh pihak
                              ketiga independen (“Penyedia Metode Pembayaran”),
                              pada Aplikasi, termasuk uang elektronik, fasilitas
                              pinjaman, tagihan perusahaan (dengan menggunakan
                              Akun pengguna yang sah yang terhubung dengan akun
                              pemberi pekerjaan Anda) kartu debit atau kredit,
                              Member bank, atau metode pembayaran lain (“Metode
                              Pembayaran") yang dapat berubah sewaktu-waktu
                              berdasarkan kebijakan kami sepenuhnya.
                            </li>
                            <li class="subJudul2">
                              Untuk dapat melakukan pembayaran melalui Metode
                              Pembayaran, Anda harus terdaftar secara resmi pada
                              Penyedia Metode Pembayaran yang Anda pilih dan
                              menggunakan kredensial pembayaran Anda sendiri.
                              Dalam hal Anda menggunakan kredensial terdaftar
                              pihak lain, Anda bertanggung jawab secara penuh
                              atas semua perizinan yang diperlukan dan semua
                              kerugian atau sengketa yang timbul antara Anda dan
                              pihak lain tersebut, baik karena kelalaian atau
                              kesalahan Anda, Kami, Penyedia Layanan, Penyedia
                              Konten Pihak Ketiga, Penyedia Penawaran atau
                              Penyedia Metode Pembayaran.
                            </li>
                            <li class="subJudul2">
                              Kami berhak menolak atau menunda untuk meneruskan
                              permintaan pembayaran Anda melalui Metode
                              Pembayaran karena alasan tertentu, termasuk namun
                              tidak terbatas pada adanya indikasi atau Kami
                              mempunyai alasan yang cukup untuk menduga adanya
                              kecurangan, penipuan, pelanggaran Ketentuan
                              Penggunaan, pelanggaran atas peraturan
                              perundang-undangan yang berlaku termasuk yang
                              terkait dengan alat pembayaran menggunakan kartu,
                              uang elektronik, pemrosesan transaksi pembayaran,
                              anti pencucian uang, korupsi dan pencegahan
                              pendanaan terorisme, atau tindakan lain yang tidak
                              wajar atau mencurigakan, termasuk belum
                              dipenuhinya kewajiban Anda kepada Kami.
                            </li>
                            <li class="subJudul2">
                              Ketentuan lebih lanjut tentang biaya yang berlaku
                              terhadap Layanan tertentu, Konten Pihak Ketiga,
                              Penawaran dan/atau Metode Pembayaran dapat
                              ditemukan dalam Ketentuan Penggunaan tambahan dari
                              Layanan yang Anda pesan dan syarat dan ketentuan
                              dari Penyedia Layanan, Penyedia Konten Pihak
                              Ketiga, Penyedia Penawaran dan/atau Penyedia
                              Metode Pembayaran.
                            </li>
                          </ul>
                          <br />
                          <li class="isiSubJudul">PEMBAHARUAN</li>
                          <ul>
                            <li class="subJudul2">
                              Syarat & ketentuan mungkin diubah dan/atau
                              diperbaharui dari waktu ke waktu tanpa
                              pemberitahuan sebelumnya. UR menyarankan agar anda
                              membaca secara seksama dan memeriksa halaman
                              Syarat & ketentuan ini dari waktu ke waktu untuk
                              mengetahui perubahan apapun. Dengan tetap
                              mengakses dan menggunakan layanan UR, maka
                              pengguna dianggap menyetujui perubahan-perubahan
                              dalam Syarat & Ketentuan.
                            </li>
                          </ul>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenChoiceOfLaw(!openChoiceOfLaw)}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Lain-lain" />
                  {openChoiceOfLaw ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openChoiceOfLaw} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            Apabila Pengguna mempergunakan fitur-fitur yang
                            tersedia dalam situs UR maka Pengguna dengan ini
                            menyatakan memahami dan menyetujui segala syarat dan
                            ketentuan yang diatur khusus sehubungan dengan
                            fitur-fitur tersebut di bawah ini, yakni:
                          </li>
                          <ul>
                            <li class="subJudul2">
                              Penggunaan fitur pemesanan dan pembelian tiket
                              kereta api melalui situs UR akan diatur lebih
                              lanjut dalam ketentuan berikut
                            </li>
                            <li class="subJudul2">
                              Segala hal yang belum danfatau tidak diatur dalam
                              syarat dan ketentuan khusus dalam fitur tersebut
                              maka akan sepenuhnya merujuk pada syarat dan
                              ketentuan UR secara umum.
                            </li>
                          </ul>
                          <li class="isiSubJudul">
                            Anda mengerti dan setuju bahwa Ketentuan Penggunaan
                            ini merupakan perjanjian dalam bentuk elektronik dan
                            tindakan Anda menekan tombol 'daftar' saat pembukaan
                            Akun atau tombol 'masuk' saat akan mengakses Akun
                            Anda merupakan persetujuan aktif Anda untuk
                            mengikatkan diri dalam perjanjian dengan Kami
                            sehingga keberlakuan Ketentuan Penggunaan ini dan
                            kebijakan Privasi adalah sah dan mengikat secara
                            hukum dan terus berlaku sepanjang penggunaan
                            Aplikasi dan Layanan oleh Anda.
                          </li>
                          <li class="isiSubJudul">
                            Anda tidak akan mengajukan tuntutan atau keberatan
                            apapun terhadap keabsahan dari Ketentuan Penggunaan
                            atau Kebijakan Privasi yang dibuat dalam bentuk
                            elektronik.
                          </li>
                          <li class="isiSubJudul">
                            Anda tidak dapat mengalihkan hak Anda berdasarkan
                            Ketentuan Penggunaan ini tanpa persetujuan tertulis
                            sebelumnya dari Kami. Namun, Kami dapat mengalihkan
                            hak Kami berdasarkan Ketentuan Penggunaan ini setiap
                            saat kepada pihak lain tanpa perlu mendapatkan
                            persetujuan terlebih dahulu dari atau memberikan
                            pemberitahuan sebelumya kepada Anda.
                          </li>
                          <li class="isiSubJudul">
                            Bila Anda tidak mematuhi atau melanggar ketentuan
                            dalam Ketentuan Penggunaan ini, dan Kami tidak
                            mengambil tindakan secara langsung, bukan berarti
                            Kami mengesampingkan hak Kami untuk mengambil
                            tindakan yang diperlukan di kemudian hari.
                          </li>
                          <li class="isiSubJudul">
                            Ketentuan ini tetap berlaku bahkan setelah pembekuan
                            sementara, pembekuan permanen, penghapusan Aplikasi
                            atau setelah berakhirnya perjanjian ini antara Anda
                            dan Kami.
                          </li>
                          <li class="isiSubJudul">
                            Jika salah satu dari ketentuan dalam Ketentuan
                            Penggunaan ini tidak dapat diberlakukan, hal
                            tersebut tidak akan memengaruhi ketentuan lainnya.
                          </li>
                          <li class="isiSubJudul">
                            Kami membuat Ketentuan Penggunaan ini dalam bahasa
                            Indonesia dan Inggris. Setiap perbedaan akan
                            diartikan sesuai dengan bahasa Indonesia.
                          </li>
                          <li class="isiSubJudul">
                            Ketentuan Penggunaan ini diatur berdasarkan hukum
                            Republik Indonesia. Setiap dan seluruh perselisihan
                            yang timbul dari penggunaan Aplikasi atau Layanan
                            tunduk pada yurisdiksi eksklusif Pengadilan Negeri
                            Jakarta Selatan.
                          </li>
                          <li class="isiSubJudul">
                            Dalam hal <a class="namaPT"></a> tidak dapat
                            melaksanakan instruksi dari User. baik sebagian
                            maupun seluruhnya, karena kejadian-kejadian atau
                            hal-hal di luar kekuasaan atau kemampuan{" "}
                            <a class="namaPT"></a>, termasuk namun tidak
                            terbatas pada bencana alam, perang, huru-hara,
                            peralatan/sistem/transmisi dalam keadaan tidak
                            berfungsi, terjadinya gangguan listrik, gangguan
                            telekomunikasi, adanya kebijakan pemerintah yang
                            melarang <a class="namaPT"></a>
                            mengoperasikan UR, serta kejadian-kejadian atau
                            hal-hal lain di luar kekuasaan atau kemampuan{" "}
                            <a class="namaPT"></a>, User dengan ini membebaskan{" "}
                            <a class="namaPT"></a> dari segala macam tuntutan
                            dalam bentuk apapun terkait dengan hal tersebut.
                          </li>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc5(!tnc5)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hak Intelektual" />
                  {tnc5 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc5} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol type="1">
                          <li class="isiSubJudul">
                            Aplikasi dan Layanan, termasuk namun tidak terbatas
                            pada, nama, logo, kode program, desain, merek
                            dagang, teknologi, basis data, proses dan model
                            bisnis, dilindungi oleh hak cipta, merek, paten dan
                            hak kekayaan intelektual lainnya yang tersedia
                            berdasarkan hukum Republik Indonesia yang terdaftar
                            baik atas nama Kami ataupun afiliasi Kami. Kami (dan
                            pemberi lisensi Kami) memiliki seluruh hak dan
                            kepentingan atas Aplikasi dan Layanan, termasuk
                            seluruh hak kekayaan intelektual terkait dengan
                            seluruh fitur yang terdapat didalamnya dan hak
                            kekayaan intelektual terkait.
                          </li>
                          <li class="isiSubJudul">
                            Tunduk pada Ketentuan Penggunaan ini, Kami
                            memberikan Anda lisensi terbatas yang tidak
                            eksklusif, dapat ditarik kembali, tidak dapat
                            dialihkan (tanpa hak sublisensi) untuk (i)
                            mengunduh, mengakses, dan menggunakan Aplikasi,
                            sebagaimana adanya, hanya pada perangkat berupa
                            telepon genggam dan/atau tablet pribadi Anda dan
                            hanya untuk keperluan terkait penggunaan Layanan,
                            dan (ii) mengakses atau menggunakan konten,
                            informasi dan materi terkait yang tersedia pada
                            Aplikasi hanya untuk kepentingan pribadi dan bukan
                            tujuan komersial. Hak dan hak istimewa lainnya yang
                            tidak secara tegas diberikan dalam Ketentuan
                            Penggunaan ini, adalah hak Kami atau pemberi lisensi
                            Kami.
                          </li>
                          <li class="isiSubJudul">
                            Setiap penggandaan, distribusi, pembuatan karya
                            turunan, penjualan atau penawaran untuk menjual,
                            penampilan baik sebagian atau seluruhnya, serta
                            penggunaan Aplikasi dan/atau Layanan, baik secara
                            digital atau lainnya atau pada perangkat selain
                            telepon genggam atau tablet, termasuk namun tidak
                            terbatas pada penggunaan aplikasi tambahan seperti
                            aplikasi modifikasi, emulator, dan lain-lain, yang
                            menyimpang dari Ketentuan Penggunaan ini, maupun
                            tujuan penggunaan yang ditentukan oleh Kami,
                            merupakan pelanggaran terhadap hak kekayaan
                            intelektual Kami.
                          </li>
                          <li class="isiSubJudul">Anda tidak boleh:</li>
                          <ul>
                            <li class="subJudul2">
                              menghapus setiap pemberitahuan hak cipta, merek
                              dagang atau pemberitahuan hak milik lainnya yang
                              terkandung dalam Aplikasi;
                            </li>
                            <li class="subJudul2">
                              menyalin, memodifikasi, mengadaptasi,
                              menerjemahkan, membuat karya turunan dari,
                              mendistribusikan, memberikan lisensi, menjual,
                              mengalihkan, menampilkan di muka umum baik
                              sebagian maupun seluruhnya, merekayasa balik
                              (reverse engineer), mentransmisikan, memindahkan,
                              menyiarkan, menguraikan, atau membongkar bagian
                              manapun dari atau dengan cara lain mengeksploitasi
                              Aplikasi (termasuk perangkat lunak, fitur dan
                              Layanan di dalamnya);
                            </li>
                            <li class="subJudul2">
                              memberikan lisensi, mensublisensikan, menjual,
                              menjual kembali, memindahkan, mengalihkan,
                              mendistribusikan atau mengeksploitasi secara
                              komersial atau membuat tersedia kepada pihak lain
                              Aplikasi dan/atau perangkat lunak dengan cara
                              menciptakan tautan (Jink) internet ke Aplikasi
                              atau "frame" atau "mirror" setiap perangkat lunak
                              pada server lain atau perangkat nirkabel atau yang
                              berbasis internet;
                            </li>
                            <li class="subJudul2">
                              meluncurkan program otomatis atau script,
                              termasuk, namun tidak terbatas pada, web spiders,
                              web crawlers, web robots, web ants, web indexers,
                              bots, virus atau worm, atau program apapun yang
                              mungkin membuat beberapa permintaan server per
                              detik, menciptakan beban berat atau menghambat
                              operasi dan/atau kinerja Aplikasi;
                            </li>
                            <li class="subJudul2">
                              menggunakan aplikasi pencarian atau pengambilan
                              kembali situs, perangkat manual atau otomatis
                              lainnya untuk mengambil (scraping), indeks
                              (indexing), survei (surveying), tambang data (data
                              mining), atau dengan cara apapun memperbanyak atau
                              menghindari struktur navigasi atau presentasi dari
                              Aplikasi atau isinya;
                            </li>
                            <li class="subJudul2">
                              menerbitkan, mendistribusikan atau memperbanyak
                              dengan cara apapun materi yang dilindungi hak
                              cipta, merek dagang, atau informasi lain yang Kami
                              miliki tanpa persetujuan tertulis terlebih dahulu
                              dari Kami atau pemilik hak yang melisensikan
                              hak-nya kepada Kami, dan
                            </li>
                            <li class="subJudul2">
                              menggunakan dan/atau mengakses secara tidak resmi
                              Aplikasi untuk (a) merusak, melemahkan atau
                              membahayakan setiap aspek dari Aplikasi, Layanan
                              atau sistem dan jaringan terkait, dan/atau (b)
                              membuat produk atau layanan tandingan serupa
                              menggunakan ide, fitur, fungsi atau grafik
                              menyerupai Aplikasi.
                            </li>
                          </ul>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc6(!tnc6)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Penyelesaian Masalah" />
                  {tnc6 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc6} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">Pilihan Hukum</li>
                          <ul>
                            <li class="subJudul2">
                              Perjanjian ini akan diatur oleh dan ditafsirkan
                              sesuai dengan hukum Republik Indonesia, tanpa
                              memperhatikan pertentangan aturan hukum. Anda
                              setuju bahwa tindakan hukum apapun atau sengketa
                              yang mungkin timbul dari, berhubungan dengan, atau
                              berada dalam cara apapun berhubungan dengan situs
                              dan/atau Perjanjian ini akan diselesaikan secara
                              eksklusif dalam yurisdiksi pengadilan Republik
                              Indonesia.
                            </li>
                          </ul>
                          <li class="isiSubJudul">Penyelesaian Masalah</li>
                          <ul>
                            <li class="subJudul2">
                              Pusat Resolusi adalah fitur yang disediakan oleh
                              UR untuk memfasilitasi penyelesaian masalah
                              transaksi antara Pembeli dan Penjual.
                            </li>
                            <li class="subJudul2">
                              Fitur ini akan secara otomatis menahan dana
                              pembayaran barang ke Penjual sampai dengan
                              permasalahan yang dilaporkan ke Pusat Resolusi
                              selesai.
                            </li>
                            <li class="subJudul2">
                              Dalam menggunakan fitur ini, Pembeli dan Penjual
                              diharapkan memberikan bukti-bukti transaksi jual
                              beli berupa foto barang, nota pembelian, slip resi
                              pengiriman dan bukti-bukti penunjang lainnya yang
                              dapat menjadi dasar pembenar atas setiap argumen
                              yang dikeluarkan oleh masing-masing pihak.
                            </li>
                            <li class="subJudul2">
                              Penyelesaian permasalahan melalui Pusat Resolusi
                              dapat berupa solusi yang dihasilkan berdasarkan
                              kesepakatan bersama antara Pembeli dan Penjual.
                            </li>
                            <li class="subJudul2">
                              Jika Penjual tidak memberikan jawaban dalam jangka
                              waktu 2 x 24 jam sejak kendala dibuat, maka
                              kendala secara otomatis akan terselesaikan
                              berdasarkan solusi yang diajukan oleh Pembeli.
                            </li>
                            <li class="subJudul2">
                              Jika tidak ditemukan kesepakatan antara Pembeli
                              dan Penjual dalam jangka waktu 2 x 24 jam, maka UR
                              memiliki hak untuk membantu menyelesaikan kendala.
                            </li>
                            <li class="subJudul2">
                              Dalam hal UR membantu menyelesaikan kendala, maka
                              sesuai dengan Syarat dan Ketentuan Poin C. 16 dan
                              Poin D. 11, Pembeli dan Penjual menyetujui bahwa
                              UR berwenang untuk mengambil keputusan atas
                              permasalahan tersebut dengan melihat bukti-bukti
                              yang ada dan/atau bukti-bukti baru yang harus
                              dilengkapi masing-masing pihak.
                            </li>
                            <li class="subJudul2">
                              UR berwenang untuk melakukan mediasi dan/atau
                              mengambil keputusan untuk penyelesaian masalah
                              didalam diskusi Pusat Resolusi, meskipun salah
                              satu pihak (Pembeli atau Penjual) belum memberikan
                              tanggapan atas solusi yang diberikan Pusat
                              Resolusi dalarn jangka waktu 2 x 24 jam, dalam
                              hal: (i) Adanya informasi dari pihak ketiga yang
                              berkaitan dalam jalannya transaksi, yakni antara
                              lain kurir pengiriman yang memberikan informasi
                              terkait status terkini dari resi pengiriman paket:
                              (ii) Bukti dari Pembeli dan/atau Penjual yang
                              dikirimkan melalui jalur komunikasi lain (email
                              UR, Layanan Pengguna. dan lainnya) yang perlu
                              diteruskan ke Pusat Resolusi dengan dasar
                              transparansi masalah: fili) Laporan dari salah
                              satu pihak disebabkan adanya kelalaian
                            </li>
                            <li class="subJudul2">
                              Pembeli atau Penjual: (iv) Salah satu pihak
                              (Pembeli atau Penjual) terindikasi melakukan
                              tindakan kecurangan: atau (v) Kondisi tertentu
                              yang menyebabkan UR perlu segera melakukan
                              mediasi.
                            </li>
                            <li class="subJudul2">
                              Atas keputusan pertama UR diatas, baik Pembeli dan
                              Penjual memiliki hak untuk banding, dengan
                              persyaratan bahwa pihak yang mengajukan upaya
                              banding harus mengajukan bukti baru di luar dari
                              bukti-bukti sebelumnya.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual dengan ini menyetujui bahwa
                              Keputusan UR atas upaya banding adalah keputusan
                              akhir yang tidak dapat diganggu gugat dan mengikat
                              pihak Penjual dan Pembeli untuk mematuhinya.
                            </li>
                            <li class="subJudul2">
                              Pengguna memahami bahwa Pusat Resolusi tidak
                              berlaku untuk produk pakaian dalam bekas, kendala
                              yang berkaitan dengan rasa, aroma dan/atau tekstur
                              produk, dan semua produk dalam daftar jenis barang
                              yang dilarang diperjual-belikan di UR seperti yang
                              ada di halaman Syarat dan Ketentuan.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              dalam penyelesaian kendala di Pusat Resolusi,
                              Pembeli dan Penjual wajib memberikan tanggapan
                              atas kendala yang ada hingga selesai dengan
                              mematuhi ketentuan dan batas waktu sebagaimana
                              yang telah ditentukan pihak UR pada diskusi
                              terkait.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              penanggungan ongkos kirim yang timbul selama
                              proses penyelesaian kendala pada Pusat Resolusi
                              didasarkan oleh bukti-bukti dan/atau kesepakatan
                              yang diberikan oleh kedua belah pihak. Apabila
                              Pembeli dan/atau Penjual tidak bersedia untuk
                              menanggung ongkos kirim tersebut, maka UR
                              berwenang memberikan keputusan penanggungan ongkos
                              kirim berdasarkan perkembangan diskusi dan bukti
                              yang ada.
                            </li>
                            <li class="subJudul2">
                              Pembeli memahami dan menyetujui bahwa setelah
                              adanya kesepakatan untuk pengiriman Barang kembali
                              ke Penjual. Apabila dalam waktu maksimal 2 x 24
                              jam (tidak termasuk hari libur nasional, Sabtu dan
                              Minggu) terhitung sejak Penjual memberikan alamat,
                              Pembeli tidak kunjung melakukan pengiriman barang,
                              maka UR berwenang untuk mengambil keputusan
                              berdasarkan perkembangan diskusi dan bukti yang
                              ada.
                            </li>
                            <li class="subJudul2">
                              Penjual memahami dan menyetujui bahwa pengiriman
                              Barang pengganti untuk Pembeli dapat dilakukan
                              maksimal 2 (dua) hari kerja (tidak termasuk hari
                              libur nasional, sabtu dan minggu) terhitung sejak
                              terdapat status pengiriman dari kurir logistik
                              bahwa Barang yang dikembalikan oleh Pembeli sudah
                              diterima oleh Penjual. Apabila didapati bahwa
                              Penjual tidak memenuhi ketentuan ini, maka UR
                              berwenang untuk mengambil keputusan berdasarkan
                              perkembangan diskusi dan bukti yang ada.
                            </li>
                            <li class="subJudul2">
                              Pembeli dan Penjual memahami dan menyetujui bahwa
                              batas waktu untuk pemberian bukti adalah maksimal
                              2 x 24 jam terhitung sejak UR melakukan permintaan
                              bukti kepada Pembeli dan Penjual atas transaksi
                              terkait.
                            </li>
                            <li class="subJudul2">
                              Tata cara menggunakan Pusat Resolusi selengkapnya
                              dapat dilihat pada Halaman Bantuan Pusat Resolusi.
                            </li>
                            <li class="subJudul2">
                              Apabila Anda mengalami gangguan sistem, mengetahui
                              atau menduga bahwa Akun Anda diretas, digunakan
                              atau disalahgunakan oleh pihak lain, atau apabila
                              perangkat telepon genggam atau tablet pribadi Anda
                              hilang, dicuri, diretas atau terkena virus, segera
                              laporkan kepada Kami sehingga Kami dapat segera
                              mengambil tindakan yang diperlukan untuk
                              menghindari penggunaan, penyalahgunaan, atau
                              kerugian yang timbul atau mungkin timbul lebih
                              lanjut.
                            </li>
                            <li class="subJudul2">
                              Apabila Anda mengalami kendala atau masalah
                              terkait Layanan, Konten Pihak Ketiga, Penawaran
                              atau pembayaran melalui Metode Pembayaran, atau
                              perlakuan Penyedia Layanan, Penyedia Konten Pihak
                              Ketiga, Penyedia Penawaran atau Penyedia Metode
                              Pembayaran Anda dapat menyampaikan keluhan Anda
                              melalui fitur yang Kami sediakan, termasuk
                              pemberian peringkat dan komentar, atau dengan
                              menghubungi Kami.
                            </li>
                            <li class="subJudul2">
                              Untuk menyampaikan keluhan, pertanyaan, sanggahan,
                              dan lain-lain (“Laporan”), Anda perlu memberikan
                              informasi yang cukup, termasuk namun tidak
                              terbatas pada, ringkasan fakta yang terjadi,
                              bukti-bukti yang Anda miliki, nomor pesanan, dan
                              informasi pribadi, seperti alamat surat elektronik
                              dan nomor telepon genggam terdaftar.
                            </li>
                            <li class="subJudul2">
                              Untuk menanggapi setiap Laporan yang Anda
                              sampaikan, Kami akan melakukan verifikasi terlebih
                              dahulu dengan mencocokan informasi yang Anda
                              berikan dan informasi pribadi Anda yang terdapat
                              dalam sistem Kami. Jika diperlukan, Kami dapat
                              secara langsung meminta Anda memberikan informasi
                              yang diperlukan untuk tujuan verifikasi.
                            </li>
                            <li class="subJudul2">
                              Kami dapat menolak untuk menindaklanjuti Laporan
                              Anda jika informasi yang Anda berikan tidak cocok
                              dengan informasi pribadi yang terdapat dalam
                              sistem Kami atau apabila Laporan disampaikan
                              terkait, terhadap, atas nama atau oleh pihak lain
                              yang berbeda dengan pemilik Akun yang bersangkutan
                              yang terdaftar secara resmi pada sistem Kami. Kami
                              dapat memberhentikan tindak lanjut terhadap
                              Laporan Anda jika Kami, dengan kebijakan Kami
                              sepenuhnya, menganggap bahwa Laporan Anda tidak
                              didukung oleh fakta-fakta yang cukup dan jelas,
                              atau telah selesai. Kami juga dapat meneruskan
                              Laporan Anda kepada Penyedia Layanan, Penyedia
                              Konten Pihak Ketiga, Penyedia Penawaran atau
                              Penyedia Metode Pembayaran untuk diselesaikan
                              secara langsung oleh Anda dan Penyedia Layanan,
                              Penyedia Konten Pihak Ketiga, Penyedia Penawaran
                              atau Penyedia Metode Pembayaran.
                            </li>
                            <li class="subJudul2">
                              Terhadap Laporan tertentu terkait Layanan, Kami
                              dapat, dengan kebijakan Kami sepenuhnya,
                              memfasilitasi pertemuan antara Anda dan Penyedia
                              Layanan untuk tujuan penyelesaian masalah secara
                              damai dan kekeluargaan. Dalam hal demikian, jika
                              Anda merasa tetap perlu mengambil tindakan lain,
                              termasuk tindakan hukum apapun, Anda dapat
                              melakukannya atas tanggung jawab pribadi Anda
                              sepenuhnya.
                            </li>
                            <li class="subJudul2">
                              Terhadap Laporan tertentu terkait Metode
                              Pembayaran, Kami dapat mengembalikan dana Anda
                              melalui cara yang ditentukan oleh Kami atau
                              bersama-sama dengan Penyedia Metode Pembayaran,
                              berdasarkan kebijakan Kami sepenuhnya. Kami tidak
                              akan mengembalikan dana kepada pihak lain yang
                              berbeda dengan kredensial terdaftar pada Akun UR
                              atau Penyedia Metode Pembayaran, termasuk apabila
                              Anda menggunakan kredensial pihak lain untuk
                              melakukan pembayaran melalui Metode Pembayaran.
                            </li>
                            <li class="subJudul2">
                              Keluhan/pengaduan kepada <a class="namaPT"></a>{" "}
                              sehubungan dengan UR dapat disampaikan oleh User
                              melalui kanal pengaduan yang disediakan oleh{" "}
                              <a class="namaPT"></a>. yaitu layanan contact
                              center URSolution <a href="#">DISINI</a>.
                            </li>
                            <li class="subJudul2">
                              Dalam keperluan penanganan keluhan/pengaduan
                              tersebut, <a class="namaPT"></a> berhak melakukan
                              verifikasi data kepada User.
                            </li>
                            <li class="subJudul2">
                              <a class="namaPT"></a> akan menanggapi keluhan
                              tersebut sesuai dengan ketentuan hukum yang
                              berlaku.
                            </li>
                            <li class="subJudul2">
                              Setiap keluhan terkait UR harus disampaikan oleh
                              User kepada <a class="namaPT"></a> dalam waktu
                              selambat-lambatnya 3 (tiga) bulan sejak tanggal
                              transaksi.
                            </li>
                            <li class="subJudul2">
                              Informasi mengenai tata cara (prosedur) penanganan
                              keluhan/pengaduan tersebut di atas dapat diakses
                              oleh User melalui website <a class="namaPT"></a>{" "}
                              atau media/sarana yang tersedia pada{" "}
                              <a class="namaPT"></a>.
                            </li>
                          </ul>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc7(!tnc7)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kondisi Kahar" />
                  {tnc7 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc7} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            Aplikasi dapat diinterupsi oleh kejadian di luar
                            kewenangan atau kontrol Kami (“Keadaan Kahar”),
                            termasuk namun tidak terbatas pada bencana alam,
                            gangguan listrik, gangguan telekomunikasi, kebijakan
                            pemerintah, dan lain-lain. Anda setuju untuk
                            membebaskan Kami dari setiap tuntutan dan tanggung
                            jawab, jika Kami tidak dapat memfasilitasi Layanan,
                            termasuk memenuhi instruksi yang Anda berikan
                            melalui Aplikasi, baik sebagian maupun seluruhnya,
                            karena suatu Keadaan Kahar.
                          </li>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setTnc8(!tnc8)}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="Persetujuan Terhadap Syarat dan Ketentuan" />
                  {tnc8 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tnc8} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ul>
                          <li class="isiSubJudul">
                            PENGGUNA dengan ini menyatakan telah memahami
                            sepenuhnya dan menyetujui seluruh Syarat dan
                            Ketentuan sebagaimana tersebut di atas.
                          </li>
                          <li class="isiSubJudul">
                            PENGGUNA menyatakan bahwa dengan mencentang (tick
                            mark) bagian dari Syarat dan Ketentuan ini yang
                            berbunyi “Saya telah membaca, memahami, dan
                            menyetujui Syarat dan Ketentuan di atas" maka
                            Nasabah telah memahami dan menyetujui Syarat dan
                            Ketentuan dan oleh karenanya persetujuan tersebut
                            merupakan bukti yang sah dan memiliki kekuatan
                            pembuktian yang sempurna sebagaimana layaknya
                            persetujuan yang diberikan dengan menandatangani
                            Syarat dan Ketentuan.
                          </li>
                        </ul>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default SyaratDanKetentuan;
