import React from 'react'
import { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

var dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime')
var advancedFormat = require('dayjs/plugin/advancedFormat')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const useStyles = makeStyles((theme) => ({
    cardSize: {
        // maxWidth: 340,
        width: 340,
        height: 310,
    },
    cardContent: {
        height: 305,
        "&:hover": {
            textDecoration: 'none',
            backgroundColor: 'white',
        }
    },
    media: {
        height: 260,
    },
}))

export default function Getberita(props) {

    const visibility = useContext(VisibilityContext);
    const history = useHistory()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });

    const classes = useStyles() 
    var images = JSON.parse(props.data[1].image)

    return (

            <div
                // onClick={() => props.onClick(visibility)}
                style={{
                    width: '370px',
                    marginRight:"30px",
                    marginTop:"20px",
                    marginBottom:"30px"
                }}
                tabIndex={0}
            >
            <Grid
                item xs={12} sm={6} lg={3} 
                // align={isDesktop ? "left" : "center"}
                style={{ marginTop: '5%', textAlign: isDesktop ? "left" : "center"}}
                key={props.data[1].id}
            >
                <Card variant="outlined" 
                    className={classes.cardSize}
                >
                    <div style={{height:"auto", cursor:"pointer"}} onClick={()=>{
                            if(props.data[1].slug.includes("https://")){
                                window.location.replace(props.data[1].slug)
                            }else {
                                history.push("/blog/" + props.data[1].slug)
                            }
                        }} >
                        {images.img1 ?
                            <div
                                style={{height:"auto", textAlign:"center"}}
                            >
                                <center>
                                    <img src={images.img1} style={{width:"100%", height:"190px", objectFit:"cover", textAlign:"center", objectPosition:"center 50%"}}/>
                                </center>
                            </div> :

                            <div></div>
                        }

                        <CardContent>
                            <Typography noWrap variant="h6" component="h2">
                                {props.data[1].title || 'Loading...'}
                            </Typography>
                        </CardContent>
                    </div>
                    <CardActions>
                        <Button size="small" color="primary" onClick={()=>{
                            if(props.data[1].slug.includes("https://")){
                                window.location.replace(props.data[1].slug)
                            } else {
                                history.push("/blog/" + props.data[1].slug)
                            }
                        }} fullWidth>
                            Read More
                        </Button>
                        {/* </Link> */}
                    </CardActions>
                </Card>
            </Grid >
        </div>



    )
}

