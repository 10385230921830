import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import PolicyIcon from "@material-ui/icons/Policy";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomLeftRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "300px",
    zIndex: "1",
  },
}));

function KebijakanPrivasi() {
  const classes = useStyles();
  const [openDefinisi, setOpenDefinisi] = React.useState(false);
  const [openInformasidiKumpulkan, setOpenInformasidiKumpulkan] =
    React.useState(false);
  const [openPenggunaanInformasi, setOpenPenggunaanInformasi] =
    React.useState(false);
  const [openPemberianInformasi, setOpenPemberianInformasi] =
    React.useState(false);
  const [openInformasiAnak, setOpenInformasiAnak] = React.useState(false);
  const [openKeamanan, setOpenKeamanan] = React.useState(false);
  const [openPerubahanPrivasi, setOpenPerubahanPrivasi] = React.useState(false);
  const [openBerentiMenerima, setOpenBerentiMenerima] = React.useState(false);
  const [openMenghubungiKami, setOpenMenghubungiKami] = React.useState(false);
  const [privacyJ, setPrivacyJ] = React.useState(false);
  const [privacyK, setPrivacyK] = React.useState(false);

  return (
    <div>
      <MetaTags>
        <title>Kebijakan Privasi | UR - Self Order & POS App</title>
        <meta name="title" content="Kebijakan Privasi" />

        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Kebijakan Privasi" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Kebijakan Privasi" />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                style={{ color: "#f5f5f5" }}
              >
                Kebijakan Privasi
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img
                  src="../../asset/images/Illustration/kebijakanPrivasi.png"
                  alt="kebijakanPrivasi"
                  width="50%"
                  className={classes.image}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Box pt={15}>
          <Grid container justify="center" className={classes.box}>
            <Grid item xs={10}>
              <Typography variant="subtitle1" gutterBottom>
                Adanya Kebijakan Privasi ini adalah komitmen nyata dari UR untuk
                menghargai dan melindungi setiap data atau informasi pribadi
                Pengguna situs www.ur-hub.com , situs-situs turunannya, serta
                aplikasi gawai UR (selanjutnya disebut sebagai "Situs").
                <br />
                Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari
                situs UR sebagaimana tercantum dalam Syarat & Ketentuan dan
                informasi lain yang tercantum di Situs) menetapkan dasar atas
                perolehan, pengumpulan, pengolahan, penganalisisan, penampilan,
                pengiriman, pembukaan, penyimpanan, perubahan, penghapusan
                dan/atau segala bentuk pengelolaan yang terkait dengan data atau
                informasi yang mengidentifikasikan atau dapat digunakan untuk
                mengidentifikasi Pengguna yang Pengguna berikan kepada UR atau
                yang UR kumpulkan dari Pengguna maupun pihak ketiga (selanjutnya
                disebut sebagai "Data Pribadi").
                <br />
                Dengan mengklik “Daftar” (Register) atau pernyataan serupa yang
                tersedia di laman pendaftaran Situs, Pengguna menyatakan bahwa
                setiap Data Pribadi Pengguna merupakan data yang benar dan sah,
                Pengguna mengakui bahwa ia telah diberitahukan dan memahami
                ketentuan Kebijakan Privasi ini serta Pengguna memberikan
                persetujuan kepada UR untuk memperoleh, mengumpulkan, mengolah,
                menganalisis, menampilkan, mengirimkan, membuka, menyimpan,
                mengubah, menghapus, mengelola dan/atau mempergunakan data
                tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan
                Privasi.
              </Typography>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setOpenDefinisi(!openDefinisi)}>
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="A. Perolehan dan Pengumpulan Data Pribadi Pengguna" />
                  {openDefinisi ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openDefinisi} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        UR mengumpulkan Data Pribadi Pengguna dengan tujuan
                        untuk memproses transaksi Pengguna, mengelola dan
                        memperlancar proses penggunaan Situs, serta
                        tujuan-tujuan lainnya selama diizinkan oleh peraturan
                        perundang-undangan yang berlaku. Adapun data Pengguna
                        yang dikumpulkan adalah sebagai berikut:
                        <ol>
                          <li>
                            Data yang diserahkan secara mandiri oleh Pengguna,
                            termasuk namun tidak terbatas pada data yang
                            diserahkan pada saat Pengguna:
                          </li>
                          <ol type="a">
                            <li>
                              membuat atau memperbarui akun UR, termasuk
                              diantaranya nama pengguna (username), alamat
                              email, nomor telepon, password, alamat, foto, dan
                              informasi lainnya yang dapat mengidentifikasi
                              Pengguna;
                            </li>
                            <li>
                              menghubungi UR, termasuk melalui layanan pelanggan
                              (customer service);
                            </li>
                            <li>
                              mengisi survei yang dikirimkan oleh UR atau pihak
                              lain yang ditunjuk secara resmi untuk mewakili UR;
                            </li>
                            <li>
                              melakukan interaksi dengan Pengguna lainnya
                              melalui fitur pesan, diskusi produk, ulasan,
                              rating, Pusat Resolusi (sebagaimana didefinisikan
                              dalam Syarat dan Ketentuan) dan fitur interaktif
                              (baik satu atau dua arah) lainnya yang terdapat
                              dalam Situs;
                            </li>
                            <li>
                              mempergunakan layanan-layanan pada Situs, termasuk
                              data transaksi yang detil, diantaranya jenis,
                              jumlah dan/atau keterangan dari produk atau
                              layanan yang dibeli, alamat pengiriman, kanal
                              pembayaran yang digunakan, jumlah transaksi,
                              tanggal dan waktu transaksi, serta detil transaksi
                              lainnya;
                            </li>
                            <li>
                              mengisi data-data pembayaran pada saat Pengguna
                              melakukan aktivitas transaksi pembayaran melalui
                              Situs, termasuk namun tidak terbatas pada data
                              rekening bank, kartu kredit, virtual account,
                              instant payment, internet banking, gerai ritel;
                              dan/atau
                            </li>
                            <li>
                              menggunakan fitur pada Situs yang membutuhkan izin
                              akses ke data yang relevan yang tersimpan dalam
                              perangkat Pengguna.
                            </li>
                          </ol>
                          <li>
                            Data yang terekam pada saat Pengguna mempergunakan
                            Situs, termasuk namun tidak terbatas pada:
                          </li>
                          <ol type="a">
                            <li>
                              data lokasi riil atau perkiraannya seperti alamat
                              IP, lokasi Wi-Fi dan geo-location;
                            </li>
                            <li>
                              data berupa waktu dari setiap aktivitas Pengguna
                              sehubungan dengan penggunaan Situs, termasuk waktu
                              pendaftaran, login dan transaksi;
                            </li>
                            <li>
                              data penggunaan atau preferensi Pengguna,
                              diantaranya interaksi Pengguna dalam menggunakan
                              Situs, pilihan yang disimpan, serta pengaturan
                              yang dipilih. Data tersebut diperoleh menggunakan
                              cookies, pixel tags, dan teknologi serupa yang
                              menciptakan dan mempertahankan pengenal unik;
                            </li>
                            <li>
                              data perangkat, diantaranya jenis perangkat yang
                              digunakan untuk mengakses Situs, termasuk model
                              perangkat keras, sistem operasi dan versinya,
                              perangkat lunak, nomor IMEI, nama file dan
                              versinya, pilihan bahasa, pengenal perangkat unik,
                              pengenal iklan, nomor seri, informasi gerakan
                              perangkat, dan/atau informasi jaringan seluler;
                              dan/atau
                            </li>
                            <li>
                              data catatan (log), diantaranya catatan pada
                              server yang menerima data seperti alamat IP
                              perangkat, tanggal dan waktu akses, fitur aplikasi
                              atau laman yang dilihat, proses kerja aplikasi dan
                              aktivitas sistem lainnya, jenis peramban
                              (browser), dan/atau situs atau layanan pihak
                              ketiga yang Pengguna gunakan sebelum berinteraksi
                              dengan Situs.
                            </li>
                          </ol>
                          <li>
                            Data yang diperoleh dari sumber lain, termasuk namun
                            tidak terbatas pada:
                          </li>
                          <ol type="a">
                            <li>
                              data berupa geo-location (GPS) dari partner UR
                              yang turut membantu UR dalam mengembangkan dan
                              menyajikan layanan-layanan dalam Situs kepada
                              Pengguna, antara lain mitra penyedia layanan
                              pembayaran, logistik atau kurir, infrastruktur
                              situs, dan mitra- mitra lainnya.
                            </li>
                            <li>
                              data berupa email, nomor telepon, nama, gender,
                              dan/atau tanggal lahir dari partner UR tempat
                              Pengguna membuat atau mengakses akun UR, seperti
                              layanan media sosial, atau situs/aplikasi yang
                              menggunakan application programming interface
                              (API) UR atau yang digunakan UR;
                            </li>
                            <li>
                              data dari penyedia layanan finansial, termasuk
                              namun tidak terbatas pada lembaga atau biro
                              pemeringkat kredit atau Lembaga Pengelola
                              Informasi Perkreditan (LPIP);
                            </li>
                            <li>
                              data dari penyedia layanan finansial (apabila
                              Pengguna menggunakan fitur spesifik seperti
                              mengajukan pinjaman melalui Situs/Aplikasi UR);
                              dan/atau
                            </li>
                            <li>
                              data berupa email dari penyedia layanan pemasaran.
                              UR dapat menggabungkan data yang diperoleh dari
                              sumber tersebut dengan data lain yang dimilikinya.
                            </li>
                          </ol>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() =>
                    setOpenInformasidiKumpulkan(!openInformasidiKumpulkan)
                  }
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="B. Penggunaan Data Pribadi" />
                  {openInformasidiKumpulkan ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openInformasidiKumpulkan}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        UR dapat menggunakan Data Pribadi yang diperoleh dan
                        dikumpulkan dari Pengguna sebagaimana disebutkan dalam
                        bagian sebelumnya untuk hal-hal sebagai berikut:
                        <ol>
                          <li>
                            Memproses segala bentuk permintaan, aktivitas maupun
                            transaksi yang dilakukan oleh Pengguna melalui
                            Situs, termasuk untuk keperluan pengiriman produk
                            kepada Pengguna.
                          </li>
                          <li>
                            Penyediaan fitur-fitur untuk memberikan, mewujudkan,
                            memelihara dan memperbaiki produk dan layanan kami,
                            termasuk:
                          </li>
                          <ol type="a">
                            <li>
                              menawarkan, memperoleh, menyediakan, atau
                              memfasilitasi layanan marketplace seperti laman
                              beranda, penelusuran, pencarian, iklan TopAds,
                              rekomendasi produk, UR Feed, asuransi, pembiayaan,
                              pinjaman, maupun produk-produk lainnya melalui
                              Situs;
                            </li>
                            <li>
                              memungkinkan fitur untuk mempribadikan
                              (personalised) akun UR Pengguna, seperti Keranjang
                              Belanja, Wishlist dan Toko Favorit; dan/atau
                            </li>
                            <li>
                              melakukan kegiatan internal yang diperlukan untuk
                              menyediakan layanan pada situs/aplikasi UR,
                              seperti pemecahan masalah software, bug,
                              permasalahan operasional, melakukan analisis data,
                              pengujian, dan penelitian, dan untuk memantau dan
                              menganalisis kecenderungan penggunaan dan
                              aktivitas.
                            </li>
                          </ol>
                          <li>
                            Membantu Pengguna pada saat berkomunikasi dengan
                            Layanan Pelanggan UR, diantaranya untuk:
                          </li>
                          <ol type="a">
                            <li>
                              memeriksa dan mengatasi permasalahan Pengguna;
                            </li>
                            <li>
                              mengarahkan pertanyaan Pengguna kepada petugas
                              layanan pelanggan yang tepat untuk mengatasi
                              permasalahan;
                            </li>
                            <li>
                              mengawasi dan memperbaiki tanggapan layanan
                              pelanggan UR;
                            </li>
                            <li>
                              menghubungi Pengguna melalui email, surat,
                              telepon, fax, dan metode komunikasi lainnya,
                              termasuk namun tidak terbatas, untuk membantu
                              dan/atau menyelesaikan proses transaksi maupun
                              proses penyelesaian kendala, serta menyampaikan
                              berita atau notifikasi lainnya sehubungan dengan
                              perlindungan Data Pribadi Pengguna oleh UR,
                              termasuk kegagalan perlindungan Data Pribadi
                              Pengguna;
                            </li>
                            <li>
                              menggunakan informasi yang diperoleh dari Pengguna
                              untuk tujuan penelitian, analisis, pengembangan
                              dan pengujian produk guna meningkatkan keamanan
                              layanan-layanan pada Situs, serta mengembangkan
                              fitur dan produk baru; dan
                            </li>
                            <li>
                              menginformasikan kepada Pengguna terkait produk,
                              layanan, promosi, studi, survei, berita,
                              perkembangan terbaru, acara dan informasi lainnya,
                              baik melalui Situs maupun melalui media lainnya.
                              UR juga dapat menggunakan informasi tersebut untuk
                              mempromosikan dan memproses kontes dan undian,
                              memberikan hadiah, serta menyajikan iklan dan
                              konten yang relevan tentang layanan UR dan
                              partnernya.
                            </li>
                          </ol>
                          <li>
                            Melakukan monitoring ataupun investigasi terhadap
                            transaksi-transaksi mencurigakan atau transaksi yang
                            terindikasi mengandung unsur kecurangan atau
                            pelanggaran terhadap Syarat dan Ketentuan atau
                            ketentuan hukum yang berlaku, serta melakukan
                            tindakan-tindakan yang diperlukan sebagai tindak
                            lanjut dari hasil monitoring atau investigasi
                            transaksi tersebut.
                          </li>
                          <li>
                            Dalam keadaan tertentu, UR mungkin perlu untuk
                            menggunakan ataupun mengungkapkan Data Pribadi
                            Pengguna untuk tujuan penegakan hukum atau untuk
                            pemenuhan persyaratan dan kewajiban peraturan
                            perundang-undangan yang berlaku, termasuk dalam hal
                            terjadinya sengketa atau proses hukum antara
                            Pengguna dan UR, atau dugaan tindak pidana seperti
                            penipuan atau pencurian data.
                          </li>
                          <li>
                            Memfasilitasi transaksi penggabungan, penjualan aset
                            perusahaan, konsolidasi atau restrukturisasi,
                            pembiayaan atau akuisisi yang melibatkan UR.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() =>
                    setOpenPenggunaanInformasi(!openPenggunaanInformasi)
                  }
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="C. Pengungkapan Data Pribadi Pengguna" />
                  {openPenggunaanInformasi ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openPenggunaanInformasi}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            UR berkomitmen untuk menjaga kerahasiaan Data
                            Pribadi Pengguna yang berada di bawah kendali UR dan
                            menjamin tidak ada pengungkapan, penjualan,
                            pengalihan, distribusi dan/atau peminjaman Data
                            Pribadi Pengguna kepada pihak ketiga lain, tanpa
                            persetujuan dari Pengguna, kecuali dalam hal-hal
                            sebagai berikut:
                            <ol type="a">
                              <li>
                                dibutuhkan adanya pengungkapan Data Pribadi
                                Pengguna kepada mitra atau pihak ketiga lain
                                yang membantu UR dalam menyajikan layanan yang
                                tersedia atau layanan yang di kemudian hari akan
                                tersedia pada Situs dan memproses segala bentuk
                                aktivitas Pengguna dalam Situs, termasuk
                                memproses transaksi, verifikasi pembayaran,
                                promosi, dan pengiriman produk.
                              </li>
                              <li>
                                UR dapat menyediakan Data Pribadi Pengguna
                                kepada Partner sesuai dengan persetujuan
                                Pengguna untuk menggunakan layanan partner,
                                termasuk diantaranya aplikasi atau situs lain
                                yang telah saling mengintegrasikan API atau
                                layanannya, atau partner yang mana UR telah
                                bekerjasama untuk menyelenggarakan promosi,
                                perlombaan, atau layanan khusus yang tersedia
                                pada Situs.
                              </li>
                              <li>
                                UR dapat menyediakan Data Pribadi Pengguna
                                kepada pihak ketiga yang menggunakan dan
                                mengintegrasikan API publik yang disediakan oleh
                                UR (termasuk namun tidak terbatas pada penyedia
                                layanan partner UR) dengan aplikasi atau situs
                                yang dioperasikannya untuk kepentingan
                                penyelesaian transaksi antara Pembeli dan
                                Penjual pada Situs atau tujuan penggunaan Data
                                Pribadi lainnya yang telah disebutkan pada
                                Bagian B Kebijakan Privasi ini.
                              </li>
                              <li>
                                dibutuhkan adanya komunikasi antara partner UR
                                (seperti penyedia logistik, pembayaran, dan
                                penyedia fitur atau fasilitas layanan lainnya)
                                dengan Pengguna dalam hal penyelesaian kendala
                                maupun hal-hal lainnya.
                              </li>
                              <li>
                                UR dapat menyediakan Data Pribadi Pengguna
                                kepada vendor, konsultan, mitra pemasaran,
                                firma/lembaga riset, atau penyedia layanan
                                sejenis dalam rangka kegiatan pemasaran yang
                                dilakukan oleh pihak ketiga, peningkatan dan
                                pemeliharan kualitas layanan UR, serta kegiatan
                                publikasi lainnya.
                              </li>
                              <li>
                                Pengguna menghubungi UR melalui media publik
                                seperti blog, media sosial, dan fitur tertentu
                                pada Situs, yang mana komunikasi antara Pengguna
                                dan UR tersebut dapat dilihat dan diketahui oleh
                                khalayak ramai.
                              </li>
                              <li>
                                UR dapat membagikan Data Pribadi Pengguna kepada
                                anak perusahaan dan afiliasinya untuk membantu
                                memberikan layanan atau melakukan pengolahan
                                data untuk dan atas nama UR.
                              </li>
                              <li>
                                UR dapat mengungkapkan dan/atau memberikan Data
                                Pribadi Pengguna kepada pihak-pihak yang
                                berkepentingan dalam rangka pelaksanaan analisis
                                kelayakan kredit Pengguna.
                              </li>
                              <li>
                                UR mengungkapkan Data Pribadi Pengguna dalam
                                upaya mematuhi kewajiban hukum dan/atau adanya
                                permintaan yang sah dari aparat penegak hukum
                                atau instansi penyelenggara negara yang
                                berwenang.
                              </li>
                            </ol>
                          </li>
                          <li>
                            Sehubungan dengan pengungkapan Data Pribadi Pengguna
                            sebagaimana dijelaskan di atas, UR akan/mungkin
                            perlu mengungkapkan Data Pribadi Pengguna kepada
                            penyedia layanan pihak ketiga, agen dan/atau
                            afiliasi atau perusahaan terkait UR, dan/atau pihak
                            ketiga lainnya yang berlokasi di luar wilayah
                            Indonesia. Meskipun demikian, penyedia layanan pihak
                            ketiga, agen dan/atau afiliasi atau perusahaan
                            terkait dan/atau pihak ketiga lainnya tersebut hanya
                            akan mengelola dan/atau memanfaatkan Data Pribadi
                            Pengguna sehubungan dengan satu atau lebih tujuan
                            sebagaimana diatur dalam Kebijakan Privasi ini dan
                            sesuai dengan ketentuan peraturan
                            perundangan-undangan yang berlaku.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() =>
                    setOpenPemberianInformasi(!openPemberianInformasi)
                  }
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="D. Cookies" />
                  {openPemberianInformasi ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openPemberianInformasi}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            Cookies adalah file kecil yang secara otomatis akan
                            mengambil tempat di dalam perangkat Pengguna yang
                            menjalankan fungsi dalam menyimpan preferensi maupun
                            konfigurasi Pengguna selama mengunjungi suatu situs.
                          </li>
                          <li>
                            Cookies tersebut tidak diperuntukkan untuk digunakan
                            pada saat melakukan akses data lain yang Pengguna
                            miliki di perangkat komputer Pengguna, selain dari
                            yang telah Pengguna setujui untuk disampaikan.
                          </li>
                          <li>
                            walaupun secara otomatis perangkat komputer Pengguna
                            akan menerima cookies, Pengguna dapat menentukan
                            pilihan untuk melakukan modifikasi melalui
                            pengaturan browser Pengguna yaitu dengan memilih
                            untuk menolak cookies (pilihan ini dapat menghambat
                            tersedianya layanan UR secara optimal pada saat
                            Pengguna mengakses Situs).
                          </li>
                          <li>
                            UR menggunakan fitur Google Analytics Demographics
                            and Interest. Data yang UR peroleh dari fitur
                            tersebut, seperti umur, jenis kelamin, minat
                            Pengguna dan informasi lainnya yang dapat
                            mengidentifikasi Pengguna, akan UR gunakan untuk
                            pengembangan fitur, fasilitas, dan/atau konten yang
                            terdapat pada Situs UR. Jika Pengguna tidak ingin
                            data miliknya terlacak oleh Google Analytics,
                            Pengguna dapat menggunakan Add-On Google Analytics
                            Opt-Out Browser.
                          </li>
                          <li>
                            UR dapat menggunakan fitur-fitur yang disediakan
                            oleh pihak ketiga dalam rangka meningkatkan layanan
                            dan konten UR, termasuk diantaranya ialah penilaian,
                            penyesuaian, dan penyajian iklan kepada setiap
                            Pengguna berdasarkan minat atau riwayat kunjungan.
                            Jika Pengguna tidak ingin iklan ditampilkan
                            berdasarkan penyesuaian tersebut, maka Pengguna
                            dapat mengaturnya melalui browser.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenInformasiAnak(!openInformasiAnak)}
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="E. Pilihan Pengguna dan Transparansi" />
                  {openInformasiAnak ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openInformasiAnak} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            Perangkat seluler pada umumnya (iOS dan Android)
                            memiliki pengaturan sehingga aplikasi UR tidak dapat
                            mengakses data tertentu tanpa persetujuan dari
                            Pengguna. Perangkat iOS akan memberikan
                            pemberitahuan kepada Pengguna saat aplikasi UR
                            pertama kali meminta akses terhadap data tersebut,
                            sedangkan perangkat Android akan memberikan
                            pemberitahuan kepada Pengguna saat aplikasi UR
                            pertama kali dimuat. Dengan menggunakan aplikasi dan
                            memberikan akses terhadap aplikasi, Pengguna
                            dianggap memberikan persetujuannya terhadap
                            pengumpulan dan penggunaan Data Pribadi Pengguna
                            dalam perangkatnya.
                          </li>
                          <li>
                            Setelah transaksi jual beli melalui Situs berhasil,
                            Penjual maupun Pembeli (sebagaimana didefinisikan
                            dalam Syarat dan Ketentuan) memiliki kesempatan
                            untuk memberikan penilaian dan ulasan terhadap satu
                            sama lain. Informasi ini mungkin dapat dilihat
                            secara publik dengan persetujuan Pengguna.
                          </li>
                          <li>
                            Pengguna dapat mengakses dan mengubah informasi
                            berupa alamat email, nomor telepon, tanggal lahir,
                            jenis kelamin, daftar alamat, metode pembayaran, dan
                            rekening bank melalui fitur pengaturan (settings)
                            pada Situs.
                          </li>
                          <li>
                            Pengguna dapat menarik diri (opt-out) dari informasi
                            atau notifikasi berupa buletin, ulasan, diskusi
                            produk, pesan pribadi, atau pesan pribadi dari Admin
                            yang dikirimkan oleh UR melalui fitur pengaturan
                            pada Situs. UR tetap dapat mengirimkan pesan atau
                            email berupa keterangan transaksi atau informasi
                            terkait akun Pengguna.
                          </li>
                          <li>
                            Sepanjang tidak bertentangan dengan ketentuan
                            peraturan perundang-undangan yang berlaku, Pengguna
                            dapat menghubungi UR untuk melakukan penarikan
                            persetujuan terhadap perolehan, pengumpulan,
                            penyimpanan, pengelolaan dan penggunaan data
                            Pengguna. Apabila terjadi demikian maka Pengguna
                            memahami konsekuensi bahwa Pengguna tidak dapat
                            menggunakan layanan Situs maupun layanan UR lainnya.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setOpenKeamanan(!openKeamanan)}>
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="F. Keamanan, Penyimpanan dan Penghapusan Data Pribadi Pengguna" />
                  {openKeamanan ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openKeamanan} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            UR melindungi setiap Data Pribadi Pengguna yang
                            disimpan dalam sistemnya, serta melindungi data
                            tersebut dari akses, penggunaan, modifikasi,
                            pengambilan dan/atau pengungkapan tidak sah dengan
                            menggunakan sejumlah tindakan dan prosedur keamanan,
                            termasuk kata sandi dan kode OTP (One Time Password)
                            Pengguna.
                          </li>
                          <li>
                            Data Pribadi Pengguna juga dapat disimpan atau
                            diproses di luar negara oleh pihak yang bekerja
                            untuk UR di negara lain, atau oleh penyedia layanan
                            pihak ketiga, vendor, pemasok, mitra, kontraktor,
                            atau afiliasi UR. Dalam hal tersebut, UR akan
                            memastikan bahwa Data Pribadi tersebut tetap
                            terlindungi sesuai dengan komitmen UR dalam
                            Kebijakan Privasi ini.
                          </li>
                          <li>
                            Walaupun UR telah menggunakan upaya terbaiknya untuk
                            mengamankan dan melindungi Data Pribadi Pengguna,
                            perlu diketahui bahwa pengiriman data melalui
                            Internet tidak pernah sepenuhnya aman. Dengan
                            demikian, UR tidak dapat menjamin 100% keamanan data
                            yang disediakan atau dikirimkan kepada UR oleh
                            Pengguna dan pemberian informasi oleh Pengguna
                            merupakan risiko yang ditanggung oleh Pengguna
                            sendiri.
                          </li>
                          <li>
                            UR akan menghapus dan/atau menganonimkan Data
                            Pribadi Pengguna yang ada di bawah kendali UR
                            apabila (i) Data Pribadi Pengguna tidak lagi
                            diperlukan untuk memenuhi tujuan dari
                            pengumpulannya; dan (ii) penyimpanan tidak lagi
                            diperlukan untuk tujuan kepatuhan terhadap peraturan
                            perundang-undangan yang berlaku.
                          </li>
                          <li>
                            Mohon diperhatikan bahwa masih ada kemungkinan bahwa
                            beberapa Data Pribadi Pengguna disimpan oleh pihak
                            lain, termasuk instansi penyelenggara negara yang
                            berwenang. Dalam hal kami membagikan Data Pribadi
                            Pengguna kepada instansi penyelenggara negara yang
                            berwenang dan/atau instansi lainnya yang dapat
                            ditunjuk oleh pemerintah yang berwenang atau
                            memiliki kerja sama dengan UR, Pengguna menyetujui
                            dan mengakui bahwa penyimpanan Data Pribadi Pengguna
                            oleh instansi tersebut akan mengikuti kebijakan
                            penyimpanan data masing-masing instansi tersebut.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenPerubahanPrivasi(!openPerubahanPrivasi)}
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="G. Akses dan Perbaikan Data Pribadi Pengguna atau Penarikan Kembali Persetujuan" />
                  {openPerubahanPrivasi ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openPerubahanPrivasi}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            UR mengambil langkah-langkah yang wajar untuk
                            memastikan bahwa Data Pribadi Pengguna diproses
                            secara akurat dan lengkap. Namun demikian, penting
                            bagi Pengguna untuk memberi tahu UR secara tepat
                            waktu tentang segala perubahan pada Data Pribadi
                            Pengguna atau jika ada kesalahan dalam Data Pribadi
                            Pengguna yang berada di bawah kendali UR.
                          </li>
                          <li>
                            Pengguna berhak untuk mengakses atau mengoreksi Data
                            Pribadi Pengguna yang berada di bawah kendali UR
                            melalui layanan pelanggan UR yang tersedia dalam
                            Kebijakan Privasi ini. Namun demikian, permohonan
                            tersebut hanya akan diproses oleh UR apabila
                            Pengguna telah menyerahkan bukti identitas yang
                            memadai untuk melakukan akses atau koreksi terhadap
                            data tersebut. UR berhak menolak permohonan untuk
                            mengakses, atau untuk memperbaiki, sebagian atau
                            semua Data Pribadi Pengguna yang UR miliki atau
                            kuasai jika diizinkan atau diperlukan berdasarkan
                            perundang-undangan yang berlaku. Hal ini termasuk
                            dalam keadaan di mana Data Pribadi tersebut dapat
                            berisi referensi kepada orang lain atau di mana
                            permintaan untuk akses atau permintaan untuk
                            mengoreksi adalah untuk alasan yang UR anggap tidak
                            relevan, tidak serius, atau menyulitkan.
                          </li>
                          <li>
                            UR dapat membebankan biaya administrasi kepada
                            Pengguna untuk menangani permintaan Pengguna untuk
                            mengakses atau mengoreksi Data Pribadi Pengguna.
                          </li>
                          <li>
                            Pengguna dapat menarik persetujuan yang telah
                            Pengguna berikan terkait dengan pemrosesan Data
                            Pribadi Pengguna yang ada di bawah kendali UR dengan
                            mengirimkan permintaan tersebut melalui layanan
                            pelanggan UR yang tercantum dalam Kebijakan Privasi
                            ini. UR akan memproses permintaan Pengguna dalam
                            kurun waktu yang wajar sejak permintaan penarikan
                            persetujuan tersebut disampaikan, dan selanjutnya
                            tidak memproses Data Pribadi Pengguna sesuai dengan
                            permintaan awal Pengguna, kecuali diwajibkan oleh
                            peraturan perundang-undangan yang berlaku. Dalam hal
                            terdapat penarikan persetujuan, UR mungkin tidak
                            dapat melaksanakan kewajiban-kewajibannya
                            berdasarkan setiap perjanjian antara Pengguna dengan
                            UR. Sehubungan dengan hal tersebut, terdapat juga
                            kemungkinan bahwa dikarenakan UR tidak dapat
                            melaksanakan kewajiban-kewajibannya oleh karena
                            Pengguna menarik persetujuannya, setiap hubungan
                            hukum antara Pengguna dan UR menjadi berakhir dan
                            tidak dapat dilanjutkan.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenBerentiMenerima(!openBerentiMenerima)}
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="H. Pengaduan terkait Perlindungan Data Pengguna" />
                  {openBerentiMenerima ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openBerentiMenerima} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        <ol>
                          <li>
                            Jika Pengguna memiliki kekhawatiran tentang
                            penanganan atau perlakuan UR terhadap Data Pribadi
                            Pengguna atau jika Pengguna yakin bahwa privasinya
                            telah dilanggar, Pengguna dapat menghubungi UR
                            melalui layanan pelanggan UR atau kepada kontak UR
                            yang tercantum dalam Kebijakan Privasi ini dengan
                            menjelaskan identitas dan sifat keluhan Pengguna.
                          </li>
                          <li>
                            UR akan menyelidiki keluhan Pengguna dan berupaya
                            untuk memberikan tanggapan terhadap keluhan tersebut
                            dalam kurun waktu yang wajar setelah menerima
                            keluhan yang disampaikan oleh Pengguna.
                          </li>
                        </ol>
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem
                  button
                  onClick={() => setOpenMenghubungiKami(!openMenghubungiKami)}
                >
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="I. Hubungi Kami" />
                  {openMenghubungiKami ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openMenghubungiKami} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        Dalam hal Pengguna memiliki pertanyaan, komentar,
                        keluhan, atau klaim mengenai Kebijakan Privasi ini atau
                        Pengguna ingin mendapatkan akses dan/atau melakukan
                        koreksi terhadap Data Pribadi miliknya, silakan hubungi
                        layanan pelanggan UR melalui laman{" "}
                        <a href="https://www.ur-hub.com/contact">
                          https://www.ur-hub.com/contact
                        </a>
                        .{" "}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setPrivacyJ(!privacyJ)}>
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="J. Penyimpanan dan Penghapusan Informasi" />
                  {privacyJ ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={privacyJ} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        UR akan menyimpan informasi selama akun Pengguna tetap
                        aktif dan dapat melakukan penghapusan sesuai dengan
                        ketentuan peraturan hukum yang berlaku.
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <List component="nav" aria-labelledby="nested-list-subheader">
                <ListItem button onClick={() => setPrivacyK(!privacyK)}>
                  <ListItemIcon>
                    <PolicyIcon />
                  </ListItemIcon>
                  <ListItemText primary="K. Pembaruan Kebijakan Privasi" />
                  {privacyK ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={privacyK} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <Typography variant="subtitle1" gutterBottom>
                        UR dapat sewaktu-waktu melakukan perubahan atau
                        pembaruan terhadap Kebijakan Privasi ini. UR menyarankan
                        agar Pengguna membaca secara seksama dan memeriksa
                        halaman Kebijakan Privasi ini dari waktu ke waktu untuk
                        mengetahui perubahan apapun. Dengan tetap mengakses dan
                        menggunakan layanan Situs maupun layanan UR lainnya,
                        maka Pengguna dianggap menyetujui perubahan-perubahan
                        dalam Kebijakan Privasi.
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default KebijakanPrivasi;
