import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import Navigation from "./components/bar/Navigation";
import Footer from "./components/bar/Footer";
import NotFound from "./components/page/NotFound";
import UnderMaintance from "./components/page/UnderMaintance";
import ScrollToTop from "./components/ScrollToTop";

import Dashboard from './pages/Home';
import News from './pages/News';
import Partner from './pages/Partner';
import NewsSlug from './pages/News/Slug';
import PusatBantuanUser from './pages/PusatBantuan/PusatBantuanUser';
import PusatBantuanPartner from './pages/PusatBantuan/PusatBantuanPartner';
import TermnCondition from './pages/TermnCondition/index'
import tncPartner from './pages/TermnCondition/partner';
import PrivacyPolice from './pages/PrivacyPolice';
import Contact from './pages/Contact';
import Career from './pages/Career';
import CareerSlug from './pages/Career/Slug';
import Faqs from './pages/Faqs';
import Pricing from './pages/Pricing'
import DeleteAccount from './pages/DeleteAccount/Form';
import ConfirmDelete from './pages/DeleteAccount/Confirmation';


import CustomerFeatures from "./pages/CustomerFeatures";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    // console.log(event)
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
export default function MyApp(props) {
  const { Component, pageProps } = props;

    return (
        <Router>
            <div>
                <Toolbar id="back-to-top-anchor" disableGutters style={{ minHeight: 0 }} />
                <Navigation />
                <ScrollToTop />
                <Switch >
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/partner" component={Partner} />
                    <Route exact path="/pusat-bantuan/user" component={PusatBantuanUser} />
                    <Route exact path="/pusat-bantuan/partner" component={PusatBantuanPartner} />
                    <Route exact path="/karir" component={Career} />
                    <Route exact path="/karir/:slug" component={CareerSlug} />
                    <Route exact path="/blog" component={News} />
                    <Route exact path="/blog/:slug" component={NewsSlug} />
                    <Route exact path="/faqs" component={Faqs} />
                    <Route exact path="/syarat-dan-ketentuan" component={TermnCondition} />
                    <Route exact path="/syarat-dan-ketentuan-partner" component={tncPartner} />
                    <Route exact path="/kebijakan-privasi" component={PrivacyPolice} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/pricing" component={Pricing} />
                    <Route exact path="/delete-account" component={DeleteAccount} />
                    <Route exact path="/delete-account-employee/:partnerId" component={DeleteAccount} />
                    <Route exact path="/confirm-delete/:token" component={ConfirmDelete} />
                    <Route exact path="/confirm-delete-employee/:partnerId/:token" component={ConfirmDelete} />
                    <Route path="/404" component={NotFound} />
                    <Redirect to="/404" />
                </Switch>
                <Footer />
                <ScrollTop {...props}>
                    <Fab color="primary" size="medium" aria-label="scroll back to top" style={{ marginBottom: '60px' }}>
                        <KeyboardArrowUpIcon />
                    </Fab>
                </ScrollTop>


            </div>

        </Router>
    );

};



