import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "react-reveal/Fade";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MetaTags from "react-meta-tags";

const useStyles = makeStyles((theme) => ({
  highlights: {
    width: 100,
    height: 100,
  },
  partnerImages: {
    width: "25vh",
    height: "25vh",
    marginTop: "25%",
  },
  offerTitle: {
    fontWeight: "600",
    paddingTop: "20px",
  },
  item2: {
    order: 3,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
  },
  init: {},
}));

export default function CustomerFeatures() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
      <MetaTags>
        <title>UR : Aplikasi POS & Self Order Pertama di Indonesia</title>
        <meta
          name="title"
          content="UR : Aplikasi POS & Self Order Pertama di Indonesia"
        />
        <meta
          name="description"
          content="UR memberikan solusi untuk sistem pemesanan self order dan memudahkan restoran mengolah transaksi."
        />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta
          property="og:title"
          content="UR : Aplikasi POS & Self Order Pertama di Indonesia"
        />
        <meta
          property="og:description"
          content="UR memberikan solusi untuk sistem pemesanan self order dan memudahkan restoran mengolah transaksi."
        />
        <meta
          property="og:image"
          content="https://ur-hub.s3.us-west-2.amazonaws.com/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta
          property="twitter:title"
          content="UR : Aplikasi POS & Self Order Pertama di Indonesia"
        />
        <meta
          property="twitter:description"
          content="UR memberikan solusi untuk sistem pemesanan self order dan memudahkan restoran mengolah transaksi."
        />
        <meta
          property="twitter:image"
          content="https://ur-hub.s3.us-west-2.amazonaws.com/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box py={5} mt={isDesktop ? 5 : 5}>
        <Box py={isDesktop ? 5 : 15} style={{ h2: { color: "#1fb0e6" } }}>
          <Container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h3"
                gutterBottom
                color="primary"
              >
                Highlights
              </Typography>
            </Grid>
            <Fade left>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                spacing={3}
                className={classes.box}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  align={isDesktop ? "right" : "center"}
                >
                  <img
                    src="./asset/images/features/fitur-scan.webp"
                    alt="scan-qr"
                    width={300}
                    height={567}
                  />
                </Grid>
                <Grid item xs={12} md={6} align={isDesktop ? "left" : "center"}>
                  <Typography variant="h2" gutterBottom>
                    Scan QR
                  </Typography>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" gutterBottom>
                      Dengan fitur Scan QR pada meja restoran yang kamu
                      kunjungi, sangat mendukung kamu melakukan self order
                      hingga melakukan pembayaran secara digital hanya dengan
                      satu sentuhan.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>

        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade right>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "right" : "center"}
                  className={classes.item2}
                >
                  <Typography variant="h2" gutterBottom>
                    Digital Menu
                  </Typography>
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="flex-end"
                    direction="row"
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Tidak perlu lagi cetak buku menu, customer dapat lihat
                      langsung menu di gadgetnya.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "left" : "center"}
                  className={classes.item3}
                >
                  <img
                    src="./asset/images/features/digital-menu.webp"
                    alt="scan-qr"
                    width={300}
                    height={567}
                  />
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade left>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  align={isDesktop ? "right" : "center"}
                >
                  <img
                    src="./asset/images/features/smart-waiter.webp"
                    alt="smart-waiter"
                    width={300}
                    height={567}
                  />
                </Grid>
                <Grid item xs={12} md={6} align={isDesktop ? "left" : "center"}>
                  <Typography variant="h2" gutterBottom>
                    Smart Waiter
                  </Typography>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" gutterBottom>
                      Ajak customer untuk berbelanja lebih dengan fitur
                      rekomendasi dari smart waiter.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade right>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "right" : "center"}
                  className={classes.item2}
                >
                  <Typography variant={isDesktop ? "h2" : "h3"} gutterBottom>
                    Pembayaran Digital
                  </Typography>
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="flex-end"
                    direction="row"
                    item
                    xs={10}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      UR telah bekerjasama dengan berbagai perusahaan eWallet,
                      memudahkan customer anda untuk bertransaksi menggunakan
                      gadget. Tidak perlu memanggil waiter atau antri di kasir.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "left" : "center"}
                  className={classes.item3}
                >
                  <img
                    src="./asset/images/features/digital-payment.webp"
                    alt="detail-order"
                    width={300}
                    height={567}
                  />
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade left>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  align={isDesktop ? "right" : "center"}
                >
                  <img
                    src="./asset/images/features/order-history.webp"
                    alt="digital-payment"
                    width={300}
                    height={567}
                  />
                </Grid>
                <Grid item xs={12} md={6} align={isDesktop ? "left" : "center"}>
                  <Typography variant="h2" gutterBottom>
                    Detail Order
                  </Typography>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" gutterBottom>
                      Tidak perlu lagi menggunakan kertas untuk mendapatkan
                      receipt dari pemesanan. Dengan aplikasi UR, customer
                      mendapatkan receipt via email, dapat melihat kembali
                      riwayat pemesanan, dan memberikan rating terhadap
                      bisnismu.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade right>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "right" : "center"}
                  className={classes.item2}
                >
                  <Typography variant="h2" gutterBottom>
                    Maps
                  </Typography>
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="flex-end"
                    direction="row"
                    item
                    xs={10}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Pelanggan dapat melihat lokasi bisnismu dengan mudah.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "left" : "center"}
                  className={classes.item3}
                >
                  <img
                    src="./asset/images/features/maps.webp"
                    alt="maps"
                    width={300}
                    height={567}
                  />
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade left>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  align={isDesktop ? "right" : "center"}
                >
                  <img
                    src="./asset/images/features/detail-partner.webp"
                    alt="detail-partner"
                    width={300}
                    height={567}
                  />
                </Grid>
                <Grid item xs={12} md={6} align={isDesktop ? "left" : "center"}>
                  <Typography variant="h2" gutterBottom>
                    Detail Partner
                  </Typography>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" gutterBottom>
                      Customer dapat melihat informasi bisnismu, carousel banner
                      promosi, juga dapat pesan pickup atau delivery menggunakan
                      Gojek atau Grab.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
        <Box py={isDesktop ? 5 : 15}>
          <Container>
            <Fade right>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.box}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "right" : "center"}
                  className={classes.item2}
                >
                  <Typography variant="h2" gutterBottom>
                    UR Loyalty
                  </Typography>
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="flex-end"
                    direction="row"
                    item
                    xs={10}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Berikan promosi menarik, voucher ulang tahun, hingga
                      kumpulkan poin melalui aplikasi.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  align={isDesktop ? "left" : "center"}
                  className={classes.item3}
                >
                  <img
                    src="./asset/images/features/loyalty-rewards.webp"
                    alt="loyalty-rewards"
                    width={300}
                    height={567}
                  />
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </Box>
      </Box>

      {/* <Box mt={20} py={isDesktop ? 5 : 15}>
        <Container>
          <Fade>
            <Grid container justify="center" alignItems="center" alignContent="center" className={classes.box} spacing={1}>
              <Grid item xs={12}>
                <Typography align="center" variant="h3" gutterBottom color="primary">
                  <img src="https://ur-hub.s3.us-west-2.amazonaws.com/assets/misc/logo-white-only.png" width="45" alt="ur" />
                  &nbsp;
                  Media
                </Typography>
              </Grid>
              <Grid item xs={12} align={isDesktop ? "right" : "center"}>
                <iframe title="video promosi UR" width="100%" height="720px" src="https://www.youtube.com/embed/HdeIWCFoDtQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Box> */}
    </div>
  );
}
