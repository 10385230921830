import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    cardContent: {
        "&:hover": {
            textDecoration: 'none',
        }
    },
}))

const boxSetting = {
    bgcolor: 'background.paper',
    m: 1,
    border: 5,
    style: { width: 'auto', height: 'auto' },
    marginTop: "5vh"
};

export default function GetCareer(props) {
    const classes = useStyles()


    return (
        <>
            <Box borderColor="primary.main" {...boxSetting} >
                <Grid container justify="center" spacing={4} alignItems="center" style={{ minHeight: "110px" }}>
                    <Grid item xs={12} md={4} align="center" >
                        <Typography variant="h6" component="h1" >
                            {props.data[1].title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} align="center">
                        <Typography variant="h6" component="h1" >
                            Bandung,Indonesia
                                </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} align="center" style={{ marginBottom: "10px" }}>

                        <Button variant="contained" color="primary" pb={5} component={Link} to={"/karir/" + props.data[1].slug} className={classes.cardContent}>
                            <div style={{ color: 'white' }}>
                                APPLY
                                    </div>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

