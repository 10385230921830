import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MetaTags from "react-meta-tags";
import Fade from "react-reveal/Fade";
import RegisterCTA from "../../components/buttons/RegisterCTA";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SalesCTA from "../../components/buttons/SalesCTA";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  hero: {
    display: "flex",
    objectFit: "contain",
    alignItems: "left",
    backgroundImage: `url(./asset/images/banner/ending.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
  },
  link: {
    color: "black",
    textAlign: "left",
  },
  box: {
    height: "auto",
  },
  image: {
    position: "absolute",
    right: "144px",
    top: "100px",
    width: "250px",
    zIndex: "1",
  },
}));

export default function Pricing() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div
      style={{
        overflowX: "hidden",
      }}
    >
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.2/tailwind.min.css"
      />
      <script
        src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.8.0/dist/alpine.min.js"
        defer
      ></script>
      <MetaTags>
        <title>Harga Aplikasi POS & Self Order UR</title>
        <meta name="title" content="Hubungi Kami" />
        <meta name="description" content="Hubungi Kami , Kami siap membantu." />
        {/* facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://ur-hub.com/" />
        <meta property="og:title" content="Hubungi Kami" />
        <meta
          property="og:description"
          content="Hubungi Kami , Kami siap membantu."
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        {/* twiter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ur-hub.com/" />
        <meta property="twitter:title" content="Hubungi Kami" />
        <meta
          property="twitter:description"
          content="Hubungi Kami , Kami siap membantu."
        />
        <meta
          property="twitter:image"
          content="https://ik.imagekit.io/urhub/assets/misc/logo-white-only.png"
        />

        <meta
          name="keywords"
          content="restoran, cafe, kasir, kode qr, ur hub, aplikasi"
        />
        <meta name="owner" content="PT. Rahmat Tuhan Lestari" />
      </MetaTags>

      <Box
        className={classes.hero}
        // style={{
        //   marginTop: isDesktop ? '100px' : ''
        // }}
      >
        <div style={{ position: "absolute" }}>
          <Grid
            container
            justify="start"
            // spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
              container
              // spacing={3}
            >
              <Grid
                item
                xs={12}
                align="center"
                style={{
                  width: "100%",
                  padding: isDesktop ? "0px 5%" : "",
                }}
              >
                <Typography
                  align={isDesktop ? "left" : "center"}
                  variant={isDesktop ? "h2" : "h3"}
                  style={{
                    color: "#ffffff",
                    margin: !isDesktop ? "0px 5%" : "",
                    marginTop: isDesktop ? "35%" : "35%",
                    fontWeight: "600",
                  }}
                >
                  Harga Aplikasi Self Order dan POS UR
                </Typography>
                <div
                  style={{
                    color: "#ffffff",
                    fontWeight: "600",
                    fontSize: isDesktop ? 20 : 18,
                    margin: !isDesktop ? "0px 5%" : "",
                    marginTop: !isDesktop ? 30 : "",
                    textAlign: isDesktop ? "left" : "center",
                  }}
                >
                  Dapatkan penawaran terbaik untuk bantu usaha kamu
                </div>
                <Grid
                  container
                  xs={12}
                  md={12}
                  style={{
                    marginTop: "5%",
                    textAlign: isDesktop ? "left" : "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ marginTop: isDesktop ? "0" : "10%" }}
                  >
                    <RegisterCTA width="35vh" height="6vh" fontSize="18" />{" "}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ marginTop: isDesktop ? "0" : "10%" }}
                  >
                    <SalesCTA
                      width={isDesktop ? "40vh" : "35vh"}
                      height="6vh"
                      fontSize="18"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      <div
        className="h-full w-full border-box

        transition-all duration-500 linear"
      >
        <div
          className="content-3-7 overflow-hidden"
          style={{ fontFamily: "Montserrat, sans-serif" }}
        >
          <div className="container my-5">
            <div className="flex flex-column flex-lg-row">
              <div className="sm:p-4 px-0 py-4 lg:w-1/3 mx-auto ">
                <div className="h-full py-8 px-11 rounded-2xl bg-white card-outline flex flex-col relative overflow-hidden">
                  <h2 className="text-xl tracking-wide mb-3 font-medium price-title">
                    Lite
                  </h2>
                  <h2 className="text-4xl tracking-wide flex items-center mb-3 font-medium text-medium-black">
                    <span>119rb</span>
                    <span className="text-base ml-2.5 font-normal price-month price-month">
                      /Bulan
                    </span>
                  </h2>
                  <p className="font-normal text-base tracking-wide mb-10 text-medium-white">
                    Cocok untuk UMKM
                    <br />
                    atau bisnis baru kamu
                  </p>
                  <div className="price-list-3-7">
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Sales Summary
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Kategori
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Menu
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      COGS
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Aplikasi Owner
                    </p>
                  </div>
                  <button className="btn-outline font-medium rounded-lg flex justify-center items-center tracking-wide py-4 w-full focus:outline-none">
                    Coba Gratis
                  </button>
                </div>
              </div>
              <div className="sm:p-4 px-0 py-4 lg:w-1/3 mx-auto ">
                <div className="h-full py-8 px-11 rounded-2xl card-outline flex flex-col relative overflow-hidden emp">
                  <h2 className="text-xl tracking-wide mb-3 font-medium text-white">
                    Business
                  </h2>
                  <h2 className="text-4xl tracking-wide flex items-center mb-3 font-medium text-white">
                    <span>239rb</span>
                    <span
                      className="text-base ml-2.5 font-normal"
                      style={{ color: "#ffffff" }}
                    >
                      /Bulan
                    </span>
                  </h2>
                  <p
                    className="font-normal text-base tracking-wide mb-10"
                    style={{ color: "#ffffff" }}
                  >
                    Cocok untuk bisnis
                    <br />
                    kamu yang sudah berkembang
                  </p>
                  <div className="text-white">
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Sales Summary
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Kategori
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Menu
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      COGS
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Aplikasi Owner
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Laba Kotor
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Laba Bersih
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Analisis Penjualan
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Inventory
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Manajemen Resep
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Shift Pegawai
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Absensi
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Voucher
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Promosi
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-6.png"
                          alt=""
                        />
                      </span>
                      Review Pelanggan
                    </p>
                  </div>
                  <button className="btn-fill text-white font-medium rounded-lg flex justify-center items-center tracking-wide py-4 w-full focus:outline-none">
                    Coba Gratis
                  </button>
                </div>
              </div>
              <div className="sm:p-4 px-0 py-4 lg:w-1/3 mx-auto ">
                <div className="h-full py-8 px-11 rounded-2xl bg-white card-outline flex flex-col relative overflow-hidden">
                  <h2 className="text-xl tracking-wide mb-3 font-medium price-title">
                    Professional
                  </h2>
                  <h2 className="text-4xl tracking-wide flex items-center mb-3 font-medium text-medium-black">
                    <span>749rb</span>
                    <span className="text-base ml-2.5 font-normal price-month">
                      /Bulan
                    </span>
                  </h2>
                  <p className="font-normal text-base tracking-wide mb-10 text-medium-white">
                    Suitable for those of you
                    <br />
                    who want to grow
                  </p>
                  <div className="price-list-3-7">
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Sales Summary
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Kategori
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Menu
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      COGS
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7 check-3-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Aplikasi Owner
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Laba Kotor
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Laba Bersih
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Analisis Penjualan
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Inventory
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Manajemen Resep
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Shift Pegawai
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Absensi
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Voucher
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Promosi
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Review Pelanggan
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Multi Cabang
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      CRM Plus
                    </p>
                    <p className="flex tracking-wide items-center text-sm mb-7">
                      <span className="w-4 h-4 mr-3 inline-flex items-center justify-center flex-shrink-0">
                        <img
                          src="http://api.elements.buildwithangga.com/storage/files/2/assets/Content/Content3/Content-3-4.png"
                          alt=""
                        />
                      </span>
                      Reservasi
                    </p>
                  </div>
                  <button className="btn-outline font-medium rounded-lg flex justify-center items-center tracking-wide py-4 w-full focus:outline-none">
                    Coba Gratis
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
