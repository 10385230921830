import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RegisterCTA from "../../../components/buttons/RegisterCTA";
import RegularButton from "../../../components/buttons/RegularButton";
import * as React from "react";
import { Paper } from "@material-ui/core";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((props) => ({
  hero: {
    backgroundImage: `url(./asset/images/banner/selfOrderBg.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    margin: "none",
  },
  learnMore: {
    color: "white",
    fontWeight: "bold",
    borderRadius: "15px",
    "&:hover": {
      color: "white",
      backgroundColor: "#1fb0e6",
      fontWeight: "bold",
      borderRadius: "15px",
    },
  },
}));

export default function SelfOrder(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      <Box
        className={classes.hero}
        style={{
          height: isDesktop ? "100vh" : "100%",
        }}
      >
        <Grid
          container
          alignItems={isDesktop ? "left" : "center"}
          justify={isDesktop ? "flex-start" : "center"}
          spacing={2}
          direction={isDesktop ? "row" : "column-reverse"}
        >
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginLeft: isDesktop ? "5%" : "0",
              }}
            >
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  marginTop: isDesktop ? "15%" : "5%",
                  fontWeight: "600",
                  textAlign: isDesktop ? "left" : "center",
                }}
              >
                Self Order
              </Typography>
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: isDesktop ? 36 : 32,
                  marginTop: !isDesktop && "5%",
                  textAlign: !isDesktop && "center"
                }}
              >
                Nikmati benefitnya
              </Typography>
              <Typography
                variant={isDesktop ? "h1" : "h2"}
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: isDesktop ? 24 : 22,
                  marginTop: !isDesktop && "3%",
                  textAlign: !isDesktop && "center"
                }}
              >
                Contactless | Cashless | Promo | Voucher | Map | Reservasi
              </Typography>
              <div
                style={{
                  marginTop: isDesktop ? "25%" : "8%",
                }}
              >
              </div>
              <Grid container justify="start" spacing={2}
                style={{
                  width: !isDesktop && "100%"
                }}
              >
                <Grid
                  item
                  xs={!isDesktop && 12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {" "}
                  <RegularButton
                    width="35vh"
                    height="6vh"
                    fontSize="18"
                    type="fill"
                    backgroundColor="#1FB0E6"
                    text="Download Sekarang"
                    href="https://partner.ur-hub.com/landing?utm_source=company+profile&utm_medium=cta&utm_id=registration"
                  />
                </Grid>
                <Grid item xs={!isDesktop && 12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    style={{
                      height: "6vh",
                      padding: props.padding ?? "30px",
                    }}
                    className={classes.learnMore}
                  >
                    <div
                      style={{
                        padding: "15px",
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "white",
                      }}
                    >
                      Pelajari Lebih Lanjut
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              alt="UR"
              src="./asset/images/banner/selfOrder.png"
              height={600}
              style={{ marginTop: "15%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
