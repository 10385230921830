import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cta: {
    color: "#333332",
    fontWeight: "bold",
    borderColor: "#de148c",
    border: "3px solid",
    borderRadius: "15px",
    "&:hover": {
      color: "white",
      backgroundColor: "#de148c",
      fontWeight: "bold",
      borderRadius: "15px",
    },
  },
}));

export default function SalesCTA(props) {
  const classes = useStyles();

  return (
    <>
      <Button
        style={{
          width: props.width,
          height: props.height,
          padding: props.padding ?? "30px",
          marginTop: props.marginTop ?? "none",
        }}
        className={classes.cta}
        variant="text"
        href="https://wa.me/6287777077771?text=Halo,%20mau%20tanya-tanya%20tentang%20UR%20dong"
        target="_blank"
      >
        <div
          style={{
            padding: "5px",
            fontWeight: "bold",
            fontSize: Number(props.fontSize),
            color: props.fontColor ?? "#fff",
          }}
        >
          Hubungi Sales
        </div>
      </Button>
    </>
  );
}
