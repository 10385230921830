import React from 'react'
import { makeStyles, Hidden } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      borderBottomLeftRadius: '50%',
      borderBottomRightRadius: '50%',
    },
  },
  box: {
    height: '30vh'
  },
  image: {
    position: 'absolute',
    right: '144px',
    top: '100px',
    width: '300px',
    zIndex: '1'
  },
}))

function UnderMaintance() {
  const classes = useStyles()

  return (
    <div>
      <Box py={15} className={classes.hero}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h3" gutterBottom style={{ color: '#f5f5f5' }}>
                Under Maintance
              </Typography>
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <img src="../asset/images/banner/comingSoon.png" alt="comming soon" width="50%" className={classes.image} />
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>

        <Box pt={5}>
          <Grid container justify="center" className={classes.box}>
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary" >
                Comming soon
              </Typography>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </div>
  )
}

export default UnderMaintance
